@keyframes err {
  0%,
  100% {
    transform: translateX(0);
  }

  12.5% {
    transform: translateX(12px);
  }

  25% {
    transform: translateX(-12px);
  }

  37.5% {
    transform: translateX(8px);
  }

  50% {
    transform: translateX(-8px);
  }

  62.5% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  87.5% {
    transform: translateX(3px);
  }
}

@keyframes inputIn {
  0% {
    display: block;
  }
  100% {
    opacity: 1;
    display: block;
    font-size: 12px;
    top: 8px;
    transform: translate(-3px, 0);
  }
}

@keyframes inputOut {
  0% {
    opacity: 1;
    display: block;
    font-size: 12px;
    top: 8px;
    transform: translate(-3px, 0);
  }
  99% {
    display: block;
  }
  100% {
    font-size: 0.875em;
    top: 50%;
    transform: translate(-3px, calc(-50% - 1px));
    opacity: 0;
    display: none;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tItem,
.mItem {
  display: none;
}
.popup_cover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.2s;
}
/*header*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 9000;
}
.headerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
  min-height: 6.875em;
}
.btn_logo {
  width: 8.313em;
}
.btn_logo img {
  width: 100%;
}
.headerMenuArea {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerMenuItem {
  font-weight: 700;
}
.headerMenuItem + .headerMenuItem {
  margin: 0 0 0 3.125em;
}
.headerSubMenuArea {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.headerSubMenuItem {
  font-weight: 400;
  font-size: 0.813em;
  color: #4e4e4e;
  position: relative;
}
.headerSubMenuItem + .headerSubMenuItem {
  margin: 0 0 0 1.538em;
}
.headerSubMenuItem + .headerSubMenuItem::before {
  content: "";
  width: 1px;
  top: 50%;
  height: calc(100% - 4px);
  position: absolute;
  left: -0.769em;
  background: #e4e4e4;
  transform: translateY(-50%);
}

.mItem .headerSection {
  height: 58px;
  min-height: 58px;
  max-width: 100%;
  justify-content: center;
}
.mItem .btnBack {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  min-height: 25px;
}
.mItem .btnHeaderRight {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.mItem .headTitle {
  font-weight: 700;
  font-size: 15px;
  position: absolute;
  top: 50%;
  left: 58px;
  transform: translateY(-50%);
}
.mItem .headTitle.centerTitle {
  position: static;
  font-size: 14px;
  font-weight: 500;
  transform: translateY(0);
  text-align: center;
  max-width: calc(100% - 78px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mItem .btnSetting {
  color: #838383;
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  text-align: right;
}

.mItem .btnTopCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.mItem .btnCenter {
  align-items: center;
}

.mItem .btn_headerInfoText {
  position: absolute;
  top: 41px;
  right: -8px;
  display: none;
}
.mItem .btn_headerInfoText span {
  display: block;
  position: relative;
  background: rgba(94, 94, 94, 0.8);
  border-radius: 9px;
  font-size: 13px;
  padding: 7px 10px;
  line-height: 1.4;
  color: #fff;
  white-space: nowrap;
}
.mItem .btn_headerInfoText span::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  border: solid transparent;
  border-bottom-color: rgba(94, 94, 94, 0.8);
  border-width: 7px;
  pointer-events: none;
  bottom: 100%;
  right: 13px;
}

.mItem .headerTextBtn {
  font-size: 14px;
  color: #9e9e9e;
  text-align: right;
  font-weight: 700;
}

.headerCaption {
  font-size: 14px;
  font-weight: 500;
  color: #d1d1d1;
}
.headerCaption span {
  color: #4e4e4e;
  font-weight: 700;
}

/*footer*/
.footer {
  background: #38383a;
  padding: 30px 20px;
}
.footerSection {
  max-width: 1200px;
  margin: 0 auto;
}
.footer_title {
  font-weight: 700;
  color: #fff;
  margin: 0 0 1.25em;
}
.mItem .footer_title {
  display: none;
}
.footer_menuBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 1.75em;
}
.footer_menuItem {
  color: #fff;
  font-weight: 700;
  font-size: 0.875em;
}
.footer_menuItem + .footer_menuItem {
  margin: 0 0 0 1.429em;
}
.footer_infoText {
  font-size: 0.813em;
  display: flex;
  align-items: flex-start;
  color: rgba(255, 255, 255, 0.7);
}
.footer_infoText + .footer_infoText {
  margin: 6px 0 0;
}
.footer_infoText span {
  color: rgba(255, 255, 255, 0.8);
  min-width: 10em;
  display: block;
}
.footer_text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.813em;
  margin: 2.154em 0 0;
}

/*footermenu*/
.footerMenu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  background: #ffffff;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.05);
  z-index: 8000;
}
.footerMenuItem {
  min-width: 20%;
  width: 20%;
  text-align: center;
  padding: 12px 5px;
  color: #7e7e7e;
  font-size: 11px;
  font-weight: 500;
}
.footerMenuItem.active {
  color: #1e1e1e;
}
.footerMenuItem > img {
  margin: 0 auto 8px;
}

/*loading*/
.loadingBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  align-items: center;
  justify-content: center;
  display: none;
}
.loadingBox.active {
  display: flex;
}
.loadingBox .spin {
  animation: spin 1s infinite;
}

/*pages*/
.headerInPage {
  padding: 6.875em 20px 6.25em;
}

/*notList*/
.notList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 0.875em;
  color: #9e9e9e;
  width: 100%;
  min-height: 17.143em;
}
.notList > img {
  margin: 0 0 0.875em;
}
.notList.minVar {
  min-height: 180px;
}

/*rightPopup*/
.rightPopupClose {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.rightPopup {
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
  display: none;
  opacity: 0;
  transition: opacity 0.2s;
}
.rightPopup.active {
  opacity: 1;
}
.rightPopup.active .popup_cover {
  opacity: 1;
}
.rightPopup_header_section {
  height: 4.25em;
  position: relative;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.rightPopup_header_title {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}
.rightPopup_contents {
  max-height: calc(100vh - 4.25em - 40px);
  overflow-y: auto;
  padding: 0;
}
.rightPopup_contents.addBtnContents .terms_text {
  padding-bottom: 136px;
}
.rightPopup_title_area {
  padding: 0 20px;
  margin: 0 0 30px;
}
.rightPopup_title {
  font-size: 1.125em;
  font-weight: 700;
}
.terms_text {
  font-size: 12px;
  color: #707070;
  padding: 20px 20px 50px;
  max-height: 480px;
  overflow-y: auto;
}
.yScroll p img {
  width: 100%;
}
.rightPopup.bPopup {
  background: rgba(53, 53, 53, 0.9);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
}
.termsIframe {
  min-height: calc(100vh - 115px);
}
.rightPopup_section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 10px;
  width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  max-width: 560px;
  overflow: hidden;
}
.minVar .rightPopup_section {
  max-width: 380px;
}
.rightPopup_contents + .pageBtn_box {
  padding: 25px 20px;
}

.onlyBtn {
  padding: 0 0 84px;
}
.onlyBtn_v2 {
  padding: 0 0 134px;
}
.rightPopupPageItemBox.minSize {
  padding: 0 0 139px;
}
.rightPopupPageItemBox.maxSize {
  padding: 0 0 186px;
}
.rightPopup_contents .popupBtn_box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 10;
  padding-bottom: 20px;
}
.errPopupSetion .popupBtn_box {
  position: static;
  padding-bottom: 0;
}
.rightPopupPageItemBox {
  max-height: calc(calc(100vh - 4.25em - 40px));
  overflow-y: auto;
}
.rightPopupPageItemBox.withdrawalPopup {
  padding: 0 0 calc(84px + 90px);
}

/*carAddPopup*/
.carAddPopup_section {
  padding: 1.875em 20px 20px;
  min-height: 18.063em;
}
.carAdd_title {
  text-align: center;
  font-size: 1.25em;
  font-weight: 300;
  margin: 0 0 2em;
}
.carAdd_title span {
  display: block;
  font-size: 0.7em;
  line-height: 160%;
  color: #4e4e4e;
  font-weight: 400;
  margin: 12px 0 0;
}
.carAdd_carNumberBox {
  width: 100%;
}
.carAdd_carNumber {
  font-size: 2.375em;
  font-weight: 700;
  text-align: center;
  background: url(/assets/images/icon/license.svg) no-repeat center;
  background-size: 100% 100%;
  padding: 0.421em 1.053em;
}
.carAdd_carInfo {
  margin: 1.25em 0 2.222em;
  text-align: center;
  font-size: 1.125em;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot {
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background: #4e4e4e;
  margin: 0 3px;
  display: inline-block;
}
.carInfoText {
  background: #f3f3f3;
  border-radius: 6px;
  padding: 8px;
  color: #9e9e9e;
  font-weight: 500;
  font-size: 13px;
  margin: 0 auto 20px;
  max-width: calc(100% - 40px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.carInfoText .dot {
  margin: 0 6px;
  background: #9e9e9e;
}
.btn_selCarSize {
  display: flex;
  align-items: center;
  padding: 1.222em;
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  font-size: 1.125em;
  color: #4e4e4e;
}
.btn_selCarSize span {
  display: block;
  width: 1px;
  height: 2.333em;
  background: #f3f3f3;
  margin: 0 1.667em;
}
.btn_selCarSize + .btn_selCarSize {
  margin: 12px 0 0;
}
.btn_selCarType {
  display: block;
  width: 100%;
  text-align: left;
  padding: 1em 0 1em 0;
  background: url(/assets/images/basic/move.svg) no-repeat center right -5px;
}
.btn_selCarType + .btn_selCarType {
  border-top: 1px solid #f3f3f3;
}
.btn_selCarType_type {
  font-weight: 500;
}
.btn_selCarType_caption {
  font-size: 0.813em;
  margin: 6px 0 0;
}

/*popup*/
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
  display: none;
}
.popupSetion {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 10px;
  width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  opacity: 0;
  transition: opacity 0.2s;
}
.btnPopupSetion .popupSetion {
  padding-bottom: calc(62px + 1.313em + 20px);
}
.popup.active .popup_cover {
  opacity: 1;
}
.popup.active .popupSetion {
  opacity: 1;
}

.popup_header_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  background: #fff;
  padding: 30px 30px 1.875em;
  z-index: 11;
}
.popup_title {
  font-size: 1.125em;
  font-weight: 700;
}
/* .popupLinkArea{margin: 1.875em 0 0;max-height: 15.000em;overflow-y: auto;height: calc(100vh - 40px - 25px - 50px - 1.875em)} */
.popupLinkItem {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  padding: 1.75em 0;
  background: url("/assets/images/basic/move.svg") no-repeat right center;
}
.popupLinkItem span {
  color: #9e9e9e;
  font-weight: 400;
  margin: 0 0 0 4px;
}
.popupLinkItem + .popupLinkItem {
  border-top: 1px solid #f3f3f3;
}

.popupTitle {
  font-weight: 500;
  font-size: 18px;
  text-align: left;
}
.popupText {
  font-size: 14px;
  margin: 8px 0 0;
  color: #838383;
}
.popup_btnBox {
  margin: 26px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn_popup {
  background: #000;
  border-radius: 9px;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  padding: 14.5px;
  width: 100%;
}
.btn_popup.notColor {
  background: #fff;
  border: 1px solid #000;
  color: #000;
  width: calc(50% - 4px);
}
.btn_popup.notColor + .btn_popup {
  width: calc(50% - 4px);
}
.popupTextCaption {
  font-size: 12px;
  color: rgba(172, 172, 172, 1);
}
.btn_selPopupClose {
  position: absolute;
  right: 20px;
  top: 20px;
}
.slideUpPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
  display: none;
}
.slideUpPopup.active .popup_cover {
  opacity: 1;
}
.slideUpPopup.active .popupSetion {
  transform: translateY(0);
}

.popup_btnBox.col2 .btn_popupLeft {
  background: #fff;
  border: 1px solid #c7c7c7;
  color: #000;
  width: calc(50% - 4px);
}
.popup_btnBox.col2 .btn_popupRight {
  width: calc(50% - 4px);
}

.popupPageItem {
  overflow-y: auto;
  padding: calc(1.875em + 30px + 1.563em) 30px 0;
  max-height: calc(100vh - 40px);
}
.btnPopupSetion .popupPageItem {
  max-height: calc(100vh - 40px - (62px + 1.313em + 20px));
}
.popup.btnInPopup .popupPageItem {
  padding: 0;
}

.rentInquiryBox + .rentInquiryBox {
  margin: 2.813em 0 0;
}
.rentInquiryBoxTitle {
  font-weight: 500;
  margin: 0 0 1.25em;
}
.rentInquiryBox.flexBox .rentInquiryItemBox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.radio_item {
  width: calc(20% - 8px);
}
.radio_item label {
  font-size: 0.875em;
  text-align: center;
  border: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.214em 10px;
}
.radio_item label img {
  display: none;
  margin: 0 8px 0 0;
}
.radio_item input:checked + label {
  border: 1px solid #1e1e1e;
}
.radio_item input:checked + label .radio_item_text {
  font-weight: 500;
}
.radio_item input:checked + label img {
  display: block;
}

.popupBtn_box {
  padding: 20px 0 0;
}
.popupSetion > .popupBtn_box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 20px;
  z-index: 11;
}
.popupBtnBtn {
  max-width: 240px;
  width: 100%;
  margin: 0 auto;
  display: block;
  padding: 11px 20px;
  background: rgba(46, 122, 231, 0.15);
  border-radius: 8px;
  color: #2e7ae7;
  font-weight: 700;
  font-size: 0.875em;
  border: 1px solid rgba(46, 122, 231, 0.15);
}
.popupBtnBtn:disabled {
  background: #f3f3f3;
  color: #9e9e9e;
  cursor: auto;
  border-color: #f3f3f3;
}
.popupBtnBtn.line {
  background: #fff;
  color: #1e1e1e;
  border-color: #7e7e7e;
}
.popupBtnBtn.notLine {
  background: #fff;
  color: #9e9e9e;
  border-color: transparent;
  padding: 0 20px;
}
.popupBtnBtn + .popupBtnBtn {
  margin: 10px auto 0;
}
.popupBtnBtn.full {
  max-width: calc(100% - 40px);
}
.popupBtnBtn.line:disabled {
  border-color: #e4e4e4;
  color: #9e9e9e;
}
.popupBtnBtn.wbColor {
  color: #2e7ae7;
  background: rgba(46, 122, 231, 0.15);
  border-color: rgba(46, 122, 231, 0.15);
}

.errPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9001;
  display: none;
}
.errPopupSetion {
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.1);
  background: #fff;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
  border-radius: 10px;
  max-width: 270px;
  padding: 1.875em 16px 16px;
  width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  opacity: 0;
  transition: opacity 0.2s;
  overflow-y: auto;
}
.errPopup.active .popup_cover {
  opacity: 1;
}
.errPopup.active .errPopupSetion {
  opacity: 1;
  box-shadow: none;
}
.errPopupText {
  font-size: 0.875em;
  text-align: center;
}
.errPopupText .snsIcon {
  width: 30px;
  height: 30px;
  margin: 0 auto 10px;
}

/*prictInfoPopup*/
.prictInfoPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9001;
  display: none;
}
.prictInfoPopupSection {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
  border-radius: 10px;
  max-width: 320px;
  width: calc(100% - 40px);
  max-height: calc(100% - 40px);
  opacity: 0;
  transition: opacity 0.2s;
}
.prictInfoPopup.active .popup_cover {
  opacity: 1;
}
.prictInfoPopup.active .prictInfoPopupSection {
  opacity: 1;
}
.prictInfoPopupArea {
  background: #fff;
  position: relative;
  z-index: 1;
  max-height: calc(100vh - 48px - 40px);
  overflow-y: auto;
}
.prictInfoPopupTextBox {
  padding: 1.25em 1.875em;
}
.prictInfoPopupText {
  font-weight: 700;
  font-size: 1.125em;
}
.prictInfoList {
  width: calc(100% - 1.875em - 1.875em);
  margin: 0 auto;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  padding: 1.5em 0;
}
.prictInfoItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.prictInfoItem + .prictInfoItem {
  margin: 13px 0 0;
}
.prictInfoName {
  color: #7e7e7e;
  font-size: 0.875em;
}
.prictInfoVal {
  text-align: right;
}
.prictInfoItem.prictInfoTotal {
  padding: 1.5em 1.875em;
}
.prictInfoItem.prictInfoTotal .prictInfoName {
  font-weight: 500;
  color: #000;
}
.prictInfoItem.prictInfoTotal .prictInfoVal {
  font-weight: 700;
}
.prictInfoPopup .popupBtn_box {
  padding: 0 1.875em 1.5em;
}
.order_info_up {
  background: url(/assets/images/img/order_info_up.svg) repeat-x center bottom -2px;
  background-size: auto 100%;
  height: 24px;
  transform: translateY(2px);
}
.order_info_down {
  background: url(/assets/images/img/order_info_down.svg) repeat-x center top -2px;
  background-size: auto 100%;
  height: 24px;
  position: relative;
  z-index: 2;
  transform: translateY(-2px);
}

/*selPopup*/
.selPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9000;
  display: none;
}
.selPopupSetion {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 24px 0 0;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  transform: translateY(100%);
  transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91);
}
.selPopupTextBox {
  padding: 0 20px 10px;
}
.selPopup_selBox {
  padding: 20px;
  max-height: 300px;
  overflow-y: auto;
}
.selPopup_sel {
  padding: 15px;
  text-align: center;
  border-radius: 15px;
  border: 0;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
}
.selPopup.active .popup_cover {
  opacity: 1;
}
.selPopup.active .selPopupSetion {
  transform: translateY(0);
}
.selPopup_sel.active {
  font-weight: 500;
  background-color: #f6f6f6;
}

/*input*/
.inputItemBox {
  position: relative;
}
.inputName {
  animation: inputOut 0.1s forwards;
  position: absolute;
  z-index: 1;
  left: 14px;
  top: 50%;
  transform: translate(-3px, calc(-50% - 1px));
  font-size: 0.875em;
  color: #7e7e7e;
  font-weight: 500;
  opacity: 0;
}
.inputItemBox.active .inputName {
  animation: inputIn 0.1s forwards;
}
.inputItem {
  padding: 17px 10px;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  font-size: 0.875em;
  width: 100%;
  font-weight: 500;
  transition: padding 0.1s;
}
.inputItem[type="password"]::placeholder {
  font-weight: 500;
  font-family: "Noto Sans KR", sans-serif;
}
.inputItem[type="password"] {
  padding-right: 45px;
}
.btn_pwCh {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.inputItemBox.active .inputItem {
  padding: 27px 10px 7px;
}
.inputItemBox + .inputItemBox {
  margin: 20px 0 0;
}
.memberSection .inputItemBox + .inputItemBox {
  margin: 10px 0 0;
}
.inputItem:disabled {
  background-color: rgba(231, 231, 231, 0.5);
  color: #acacac;
}
.inputBox.bColor .inputItem:disabled {
  color: #7e7e7e;
}
.col2Input {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.col2Input .inputItemBox {
  margin: 0;
  width: 100%;
}
.inputItemBox + .col2Input {
  margin: 20px 0 0;
}
.col2Input + .col2Input {
  margin: 20px 0 0;
}
.addServiceItem + .col2Input {
  margin: 20px 0 0;
}
.service_minInputSection + .inputItemBox,
.inputItemBox + .service_minInputSection {
  margin: 20px 0 0;
}
.inputBox.notInput .inputItem {
  background: #f3f3f3;
  border: 1px solid #d1d1d1;
  color: #7e7e7e;
}

.chk_item {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chk_item.allChkItem {
  background: transparent;
}
.chk_item.allChkItem .chk_item_text {
  font-size: 1em;
  font-weight: 700;
}
.chk_item label {
  position: relative;
  padding: 0 0 0 36px;
  width: calc(100% - 34px);
}
.chk_item label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  background: url(/assets/images/basic/chk_off.svg) no-repeat center;
  background-size: 100%;
}
.chk_item input:checked + label::before {
  background-image: url(/assets/images/basic/chk_on.svg);
}
.chk_item_text {
  color: #5e5e5e;
  font-size: 0.938em;
}
.chk_item > img {
  margin: 0 0 0 10px;
}
.btnChk_area .chk_item {
  padding: 15px 0;
}
.chkBoxBtn {
  color: #2e7ae7;
  font-weight: 700;
  font-size: 13px;
  text-decoration-line: underline;
  cursor: pointer;
}
.chk_item + .chk_item {
  margin: 10px 0 0;
}
.chk_item {
  background: #f9f9f9;
  border-radius: 10px;
}

.chk_item.addServiceItem {
  background: transparent;
  border-radius: 0;
  padding: 0;
}
.chk_item.addServiceItem input:checked + label {
  border: 1px solid #1e1e1e;
}
.chk_item.addServiceItem label {
  padding: 1.125em 20px;
  width: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  cursor: pointer;
}
.chk_item.addServiceItem label::before {
  display: none;
  cursor: auto;
}
.chk_item.addServiceItem input:disabled + label {
  background: #f3f3f3 !important;
  border: 1px solid #e4e4e4 !important;
}
.chk_item.addServiceItem input:disabled + label .addServiceItem_name {
  color: #9e9e9e !important;
}
.addServiceNotCaption {
  font-size: 0.875em;
  color: #9e9e9e;
  font-weight: 500;
  text-align: right;
}
.addServiceNotCaption span {
  display: block;
  font-size: 11px;
}

.chk_item.addServiceItem + .chk_item {
  margin: 15px 0 0;
}
.addServiceItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addServiceItem_name {
  font-weight: 500;
}
.addServiceItem_name span {
  margin: 0 0 0 7px;
}
.addServiceItem_name span.gColor {
  color: #9e9e9e;
}
.addServiceCaption {
  color: #48c581;
  font-size: 0.813em;
  font-weight: 700;
  padding: 4px 7px;
  background: #e4f6ec;
  border-radius: 6px;
}
.addServiceCaption.blue_color {
  color: #2e7ae7;
  background: #e0ebfb;
}

.inputCaption {
  font-size: 0.813em;
  color: #7e7e7e;
  margin: 4px 0 0;
}

.certificationInputItem {
  padding-right: calc(25px + 1.857em);
}
.certificationCount {
  color: #2e7ae7;
  font-size: 0.875em;
  right: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.errMsg {
  margin: 4px 0 0;
  font-weight: 500;
  color: #fc4747;
  font-size: 13px;
}
.err .inputItem {
  animation: err 0.3s;
  border-color: #ff3344;
}
.btnCom {
  display: flex;
  justify-content: space-between;
}
.btnComInputBox {
  width: calc(100% - 7.5em - 10px);
}
.certificationInput {
  width: calc(100% - 7.5em - 10px);
  position: relative;
}
.btn_inputSubBtn {
  border: 1px solid #c7c7c7;
  min-width: 8.571em;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875em;
}
.btn_inputSubBtn:disabled {
  color: #9e9e9e;
}

.textAreaBox {
  position: relative;
}
.textareaItem {
  display: block;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
  padding: 10px 6px;
  font-size: 0.875em;
  resize: none;
  min-height: 100px;
}
.textareaItem:disabled {
  background-color: rgba(231, 231, 231, 0.5);
  color: #acacac;
}
.textareaCount {
  font-weight: 500;
  font-size: 12px;
  position: absolute;
  width: 100%;
  text-align: right;
  bottom: 0;
  right: 0;
  padding: 0 10px 10px;
  background: #fff;
  border-radius: 7px;
  color: #7e7e7e;
}

.inputBox .maxChk {
  padding: 10px 10px 39px;
  border: 1px solid #e7e7e7;
  border-radius: 7px;
}
.inputBox .maxChk .textareaItem {
  border: 0;
  padding: 0;
}

.inputBox.outCount .textAreaBox {
  padding: 0;
  border: 0;
  border-radius: 0;
}
.inputBox.outCount .textareaItem {
  padding: 21px 14px;
  min-height: 120px;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
}
.inputBox.outCount .textareaCount {
  position: static;
  width: 100%;
  padding: 0;
  display: block;
  margin: 4px 0 0;
}
.inputBox.outCount .textareaCount span {
  color: #2e7ae7;
}

.inputFileBox label {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
  border-radius: 0;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 100%;
  cursor: pointer;
}
.inputFile_label_basic {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.inputFile_label_text {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  color: #838383;
  margin: 15px 0 0;
}
.delImage {
  position: absolute;
  top: 14px;
  right: 14px;
}
.fileImage_item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #f6f6f6;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 100%;
}
.inputFileList {
  width: calc(100% + 20px);
  padding: 0 0 0 20px;
  transform: translateX(-20px);
  overflow-x: auto;
  display: flex;
  gap: 1.125em;
}
.inputFileBox .inputFileList label {
  border: 1px dashed #d1d1d1;
  width: 6.25em;
  min-width: 6.25em;
  height: 6.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.fileItemMultipleItem {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 6.25em;
  min-width: 6.25em;
  height: 6.25em;
  border-radius: 0;
  position: relative;
}
.fileItemMultipleItemDel {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}
.inputFileList:first-child {
  margin: 0;
}

.inputBox.addrInputBox .inputItem {
  background: url(/assets/images/basic/sh.svg) no-repeat right 10px center;
  padding-right: 45px;
}

.inputBox.type2 .textareaItem,
.inputBox.type2 .inputItem {
  padding: 10px;
  border: 1px solid #e7e7e7;
  border-radius: 7px;
}
.inputBox.type2 .inputItem.customSel {
  background-position: right 10px center;
}

.inputItem.customSel {
  background: url(/assets/images/basic/sel.svg) no-repeat right center;
  padding-right: 34px;
}
.registrationNumberBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.registrationNumberBox > .inputItem {
  width: calc(50% - 12px);
}
.registrationNumberBox > span {
  display: block;
  width: 24px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
}
.registrationNumberBackBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 12px);
}
.registrationNumberBackBox .inputItem {
  width: 30px;
  min-width: 30px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.registrationNumberBack_text {
  font-weight: 700;
  width: calc(100% - 30px);
  letter-spacing: 3px;
}

.toggleBox label {
  cursor: pointer;
  display: flex;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
}
.toggleBox label span {
  background: #acacac;
  border-radius: 23px;
  width: 35px;
  height: 20px;
  position: relative;
  transition: background 0.3s;
}
.toggleBox label span::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
  background: #fff;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  box-shadow: 0px 0px 2px #9a9a9a;
  transition: left 0.3s cubic-bezier(0.89, 0.11, 0.09, 0.89);
}
.toggleBox .toggleChk:checked + label span {
  background: #2e7ae7;
}
.toggleBox .toggleChk:checked + label span::before {
  left: calc(100% - 17px);
}

.inputBox.customSelectInputBox .inputItem {
  background: url(/assets/images/basic/selIcon.svg) no-repeat center right 10px;
  padding-right: 42px;
}
.inputBox.customSelectInputBox .inputItem:disabled {
  background-color: rgba(231, 231, 231, 0.5);
  color: #7e7e7e;
}
.inputBox.outCount .textAreaBox + .inputCaption {
  position: absolute;
  top: calc(100% - 1.65em);
  left: 0;
  width: calc(100% - 90px);
}

/*datepicker*/
.col2Input .react-datepicker-popper {
  width: 604px;
}
.react-datepicker__tab-loop {
  width: 100%;
}
.react-datepicker__tab-loop__start {
  display: none;
}
.react-datepicker-popper {
  padding: 0;
  width: 100%;
  z-index: 10;
  max-width: 604px;
}
.react-datepicker {
  border: 2px solid #1e1e1e;
  border-radius: 8px;
  font-size: 0.875em;
  width: 100%;
  padding: 20px 20px 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
.react-datepicker__day-names {
  margin: 0;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  display: none;
}
.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker__header {
  background: transparent;
  border: 0;
  padding: 12px 0 0;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 700;
  font-size: 13px;
  margin: 0 0 14px;
  color: #1e1e1e;
}
.react-datepicker__day-names {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  min-width: 14.28%;
  margin: 0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.react-datepicker__month {
  margin: 0;
}
.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  min-width: 14.28%;
  margin: 0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #1e1e1e;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #d1d1d1;
}
.react-datepicker__navigation {
  width: 21px;
  height: 21px;
  background-size: 100%;
  top: 12px;
}
.react-datepicker__navigation:disabled {
  opacity: 0.4;
}
.react-datepicker__navigation--previous {
  left: 0px;
  background: url(/assets/images/basic/cal_left.svg) no-repeat center;
}
.react-datepicker__navigation--next {
  right: 0px;
  background: url(/assets/images/basic/cal_right.svg) no-repeat center;
}
.react-datepicker__navigation-icon {
  display: none;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background: transparent;
  position: relative;
}
.react-datepicker__day.react-datepicker__day--in-range,
.react-datepicker__day.react-datepicker__day--in-selecting-range:hover {
  background: rgba(30, 30, 30, 0.05);
}
.react-datepicker__day:hover:not(.react-datepicker__day--disabled):not(
    .react-datepicker__day--outside-month
  )::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 32px;
  border-radius: 100%;
  border: 1px solid #1e1e1e;
}
.react-datepicker__day--disabled,
.react-datepicker__day--outside-month {
  background: transparent !important;
}
.react-datepicker__day span {
  position: relative;
  z-index: 1;
}
.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__quarter-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.react-datepicker__year-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background: transparent;
}
.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ):not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  ) {
  border-radius: 0;
  background: rgba(30, 30, 30, 0.05);
}
.react-datepicker__day--in-range:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  ),
.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  ) {
  border-radius: 0;
  background: rgba(30, 30, 30, 0.05);
}
.react-datepicker__day--selected:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  ),
.react-datepicker__day--range-end:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  ),
.react-datepicker__day--selecting-range-start:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  ),
.react-datepicker__day--selecting-range-end:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  ) {
  position: relative;
  background: transparent !important;
}
.react-datepicker__day--selected:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  )::before,
.react-datepicker__day--range-end:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  )::before,
.react-datepicker__day--selecting-range-start:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  )::before,
.react-datepicker__day--selecting-range-end:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  )::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 32px;
  border: 1px solid #1e1e1e;
  border-radius: 100% !important;
  background: #1e1e1e !important;
}
.react-datepicker__day--selected:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  )::after,
.react-datepicker__day--selecting-range-start:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  )::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(30, 30, 30, 0.05);
}
.react-datepicker__day--range-end:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  )::after,
.react-datepicker__day--selecting-range-end:not(
    .react-datepicker__day--disabled,
    .react-datepicker__day--outside-month
  )::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(30, 30, 30, 0.05);
}
.react-datepicker__day--selected span,
.react-datepicker__day--range-end span,
.react-datepicker__day--selecting-range-start span,
.react-datepicker__day--selecting-range-end span {
  color: #fff;
}
.react-datepicker__day--keyboard-selected {
  background: transparent !important;
}
.react-datepicker__triangle {
  display: none;
}

/*timeSelect*/
.timeSelectPopup {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100%;
  border: 2px solid #1e1e1e;
  border-radius: 8px;
  background: #fff;
  padding: 20px;
  margin: 4px 0 0;
}
.timeSelectPopup.notSizing {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 0;
  top: calc(34px + 1.349em);
}
.timeSelect_header {
  margin: 0 0 1.875em;
}
.timeSelect_title {
  text-align: center;
  font-weight: 700;
}
.timeSelectArea {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border-top: 1px solid #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  margin: 0 0 1.25em;
}
.timeSelectBox {
  width: 100%;
  border: 0;
}
.timeSelectItem {
  width: 100%;
  border: 0;
  font-size: 0.938em;
  font-weight: 500;
  padding: 1.333em 30px 1.333em 10px;
  background: url(/assets/images/basic/select.svg) no-repeat right 10px center;
}
.timeSelectCaption {
  font-size: 13px;
  color: #7e7e7e;
  text-align: center;
}
.timeSelectCaption + .popupBtn_box {
  padding: 2.5em 0 0;
}

/*tap*/
.tapSection {
  border-top: 6px solid #f6f6f6;
  border-bottom: 1px solid #d8d8d8;
}
.tapBox {
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  gap: 1.5em;
  overflow-x: auto;
  padding: 0 20px;
}
.tapItem {
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding: 10px 5px 1.5em;
  font-size: 1.25em;
  border-bottom: 2px solid transparent;
  color: #7e7e7e;
  font-weight: 400;
  white-space: nowrap;
}
.tapItem.active {
  border-color: #1e1e1e;
  color: #1e1e1e;
  font-weight: 500;
}

/*pageBtn*/
.pageBtn_box {
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1001;
}
.pageBtn_box.pageInType {
  position: static;
  padding: 0;
}
.pageBtn_box.pcMinBtn .pageBtn {
  max-width: 340px;
  margin-right: auto;
  margin-left: auto;
}
.pageBtn {
  font-size: 0.875em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  background: #2e7ae7;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  border: 1px solid #2e7ae7;
}
.pageBtn.wType {
  color: #1e1e1e;
  border-color: #c7c7c7;
  background: #fff;
}
.pageBtn.line {
  color: #1e1e1e;
  border-color: #7e7e7e;
  background: #fff;
}
.greenBtn .pageBtn {
  background: #2bdcc7;
  border-color: #2bdcc7;
}
.pageBtn_box.col2 {
  display: flex;
  justify-content: space-between;
}
.pageBtn.greenBtn {
  background: #2bdcc7;
  border-color: #2bdcc7;
}
.pageBtn_box.col2 .pageBtn {
  width: calc(50% - 5px);
  margin-top: 0;
}
.pageBtn:disabled {
  background: #d8d8d8;
  border-color: #d8d8d8;
}
.pageBtnV2 .pageBtn + .pageBtn {
  margin: 10px 0 0;
}

.horizontalScroll {
  display: flex;
  width: 100%;
  overflow-x: auto;
}
.fixedItem {
  position: fixed;
  left: 0;
  top: 54px;
  width: 100%;
  z-index: 9000;
  background: #fff;
  padding: 12px 20px;
}

.topTap {
  position: fixed;
  top: 54px;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1000;
}
.bottomBtnPage {
  padding-bottom: 78px;
}
.btnTextType {
  font-weight: 400;
  font-size: 13px;
  color: #7e7e7e;
  margin: 0 0 20px;
}

/*bottomErrMsg*/
.bottomErrMsg {
  position: absolute;
  bottom: 10%;
  transform: translate(-50%, 100%);
  max-width: 420px;
  text-align: center;
  padding: 10px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  opacity: 0;
  background: rgba(94, 94, 94, 0.8);
  border-radius: 100px;
  left: 50%;
  width: calc(100% - 40px);
  display: none;
  transition: opacity 0.2s;
  z-index: 9999;
}
.bottomErrMsg.blueMsg {
  background: rgba(46, 122, 231, 0.1);
  color: #2e7ae7;
}
.bottomErrMsg.redMsg {
  background: rgba(252, 71, 71, 0.1);
  color: #fc4747;
}
.bottomErrMsg.active {
  opacity: 1;
}
.bottomMsgPage {
  position: relative;
}

/*pageNation*/
.pageNation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.btn_pageNation_prev {
  margin: 0 10px 0 0;
  min-width: 33px;
}
.btn_pageNation_next {
  margin: 0 0 0 10px;
  min-width: 33px;
}
.btn_pageNationNumber {
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  width: 33px;
  height: 33px;
  min-width: 33px;
  font-weight: 500;
  color: #7e7e7e;
}
.btn_pageNationNumber.active {
  border: 1px solid #1e1e1e;
  color: #1e1e1e;
}

.customSelect_optionBox {
  height: 10.625em;
  position: absolute;
  background: #fff;
  border: 2px solid #1e1e1e;
  border-radius: 8px;
  z-index: 2;
  top: calc(100% + 4px);
  overflow-y: auto;
  display: none;
  width: 100%;
}
.customSelect_optionBox.active {
  display: block;
}
.customSelect_option {
  display: block;
  text-align: left;
  width: 100%;
  font-size: 0.875em;
  height: 3.3em;
  padding: 0 10px;
}
.customSelect_option:hover {
  background: #f3f3f3;
}
.customSelect_option.active {
  background: #f3f3f3;
  font-weight: 700;
}

/*member*/
.memberTitle {
  font-size: 1.375em;
  font-weight: 700;
}
.memberSubTitle {
  font-size: 0.875em;
  margin: 10px 0 0;
  color: #4e4e4e;
}
.memberSection {
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  padding: 3.125em;
  max-width: 420px;
  margin: 1.875em auto 0;
}
.loginInputBox {
  margin: 1.875em 0;
}

.loginSubMenu {
  border-top: 1px solid #f3f3f3;
  padding: 1.875em 0 0;
  margin: 1.875em 0 0;
}
.linkJoin {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 42px;
  border: 1px solid #7e7e7e;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.875em;
}
.linkFindBox {
  margin: 1.563em 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkFindItem {
  padding: 0 20px;
  font-size: 0.875em;
  position: relative;
}
.linkFindItem + .linkFindItem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 12px;
  background: #e4e4e4;
  transform: translateY(-50%);
}
.snsLoginBox {
  margin: 1.875em 0 0;
}
.snsLoginTitle {
  font-size: 0.875em;
}
.snsLoginItemBox {
  margin: 1.25em auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 240px;
}

.memberInput_section {
  margin: 0 0 1.875em;
}
.memberTitleItem {
  display: flex;
}
.memberTitleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.memberCaption {
  font-weight: 500;
  color: #d1d1d1;
}
.memberCaption span {
  font-weight: 700;
  color: #4e4e4e;
}
.memberTitleBack {
  margin: 0 14px 0 0;
}
.memberTitleArea + .memberArea .memberInput_section:nth-child(1) {
  margin-top: 3.75em;
}

.memberTitleArea + .memberArea .memberInput_section.joinInput_section {
  margin: 1.875em 0;
  padding: 1.875em 0 0;
  border-top: 1px solid #f3f3f3;
}

/*main*/
.mainPage.headerInPage {
  padding-left: 0;
  padding-right: 0;
}
.mainBanner_seciton {
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.mainBannerArea .swiper {
  height: 100%;
}
.mainBannerArea {
  width: 59%;
}
.mainBannerBox {
  height: 100%;
}
.mainBannerItem {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; /* padding: 0 0 69.71%; */
  height: 100%;
  display: block;
  border-radius: 22px;
}
.custom_pagination {
  position: absolute;
  top: 3.75em;
  left: 3.125em;
  z-index: 11;
  color: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.custom_pagination .line {
  width: 2.375em;
  height: 1px;
  display: inline-block;
  background: #fff;
  margin: 0 14px;
}
.mainBanner_menuArea {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: calc(41% - 20px);
}
.mainMenuItem {
  background-color: rgb(46, 122, 231);
  border-radius: 22px;
  padding: 1.875em 1.5em;
  height: 19.375em;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(50% - 10px);
}
.mainMenuItem_textInfo > img {
  margin: 0 0 1.125em;
}
.mainMenuItem_name {
  font-weight: 700;
  font-size: 1.125em;
  color: #fff;
}
.mainMenuItem_text {
  font-size: 13px;
  color: #fff;
  margin: 7px 0 0;
}
.mainMenuItem_captionBox {
  width: 100%;
}
.mainMenuItem_caption {
  width: 100%;
  color: #fff;
  font-size: 0.938em;
  font-weight: 500;
  padding-right: 20px;
  background: url(/assets/images/basic/move_w.svg) no-repeat center right -6px;
}
.mainMenuItem_gColor .mainMenuItem_name {
  color: #7e7e7e;
}
.mainMenuItem_gColor .mainMenuItem_text {
  color: #7e7e7e;
}
.mainMenuItem_gColor .mainMenuItem_caption {
  color: #7e7e7e;
  background-image: url(/assets/images/basic/move_g.svg);
}

.mainMenuItem.bColor {
  background-color: rgb(46, 122, 231);
}
.mainMenuItem.bgColor {
  background-color: #7e7e7e;
}
.mainMenuItem.mainMenuItem_gColor {
  background-color: #eee;
}

.mainMenuItem.bColor:hover {
  background: url(/assets/images/icon/cover.svg) no-repeat center;
  background-size: 200%;
  background-color: rgb(46, 122, 231);
}
.mainMenuItem.bgColor:hover {
  background: url(/assets/images/icon/cover.svg) no-repeat center;
  background-size: 200%;
  background-color: #7e7e7e;
}
.mainMenuItem.mainMenuItem_gColor:hover {
  background: url(/assets/images/icon/cover.svg) no-repeat center;
  background-size: 200%;
  background-color: #eee;
}

.mainSubBanner_seciton {
  max-width: 1240px;
  margin: 6.25em auto 0;
  padding: 0 20px;
}
.middleBanner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 0 0 18.33%;
  display: block;
}
.middleBanner_pagination {
  position: absolute;
  z-index: 11;
  border-radius: 15px;
  background: rgba(30, 30, 30, 0.5);
  bottom: 2.5em;
  left: 3.125em;
  padding: 8px 10px;
  display: flex;
}
.middleBanner_pagination button {
  padding: 0 6px 0 0;
}
.middleBanner_pagination span + button {
  padding: 0 0 0 6px;
}
.middleBanner_pagination span {
  font-size: 13px;
  color: #fff;
  font-weight: 500;
}
.middleBanner_pagination .sizing {
  margin: 0 3px;
}

.mainServiceSection {
  margin: 6.25em auto 0;
}
.mainService_title {
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
  font-size: 1.5em;
  font-weight: 700;
}
.mainService_slideSection {
  margin: 2.5em auto 0;
  max-width: 1240px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  padding: 0 20px;
}
.mainService_controlArea {
  min-width: 25%;
}
.mainService_control_toBox {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.mainService_control_toItem {
  background: #f3f3f3;
  border-radius: 100px;
  font-size: 0.875em;
  color: #7e7e7e;
  border: 1px solid #f3f3f3;
  padding: 1em;
}
.active.mainService_control_toItem {
  border: 1px solid #1e1e1e;
  background: #fff;
  color: #1e1e1e;
}
.mainService_slideArea {
  width: calc(((100vw - 1240px) / 2) + 75%);
}
.mainService_slideArea .swiper {
  padding-right: calc(100% - 260px);
  padding-top: 3em;
}
.mainService_slideItem_0 {
  background: linear-gradient(180deg, #fbc9c7 0%, #ffe0de 100%);
}
.mainService_slideItem_1 {
  background: linear-gradient(180deg, #c1efce 0%, #d6f7df 100%);
}
.mainService_slideItem_2 {
  background: linear-gradient(180deg, #fae3aa 0%, #fde8b3 100%);
}
.mainService_slideItem_3 {
  background: linear-gradient(180deg, #c3dcf9 0%, #c6e0fe 100%);
}
.mainService_slideItem_4 {
  background: linear-gradient(180deg, #c9efff 0%, #d8f3ff 100%);
}
.mainService_slideItem_5 {
  background: linear-gradient(180deg, #bea7ff 0%, #bea7ff 100%);
}

.mainService_slideItem_0::before {
  background: url("/assets/images/img/main_slide_0.png") no-repeat right bottom
    20px;
  background-size: 11.875em;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.mainService_slideItem_1::before {
  background: url("/assets/images/img/main_slide_1.png") no-repeat right bottom
    20px;
  background-size: 11.875em;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.mainService_slideItem_2::before {
  background: url("/assets/images/img/main_slide_2.png") no-repeat right bottom
    20px;
  background-size: 11.875em;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.mainService_slideItem_3::before {
  background: url("/assets/images/img/main_slide_3.png") no-repeat right bottom
    20px;
  background-size: 11.875em;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.mainService_slideItem_4::before {
  background: url("/assets/images/img/main_slide_4.png") no-repeat right bottom
    20px;
  background-size: 11.875em;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.mainService_slideItem_5::before {
  background: url("/assets/images/img/main_slide_5.png") no-repeat right bottom
    20px;
  background-size: 11.875em;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.mainService_slideItem {
  text-align: left;
  border-radius: 22px;
  padding: 1.875em;
  height: 20em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  transition: 0.3s transform;
  cursor: pointer;
}
.mainService_slideItem::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.mainService_slideTitle {
  font-weight: 700;
  position: relative;
  z-index: 2;
}
.mainService_slideText {
  font-weight: 500;
  color: rgba(30, 30, 30, 0.6);
  margin: 1em 0 0;
  font-size: 0.875em;
  position: relative;
  z-index: 2;
}
.mainService_controlBtnBox {
  margin: 3.75em 0 0;
  gap: 16px;
  display: flex;
  align-items: center;
}
.swiper-slide-active .mainService_slideItem {
  transform: translateY(-3em);
}
.mainBoard_section {
  max-width: 1240px;
  margin: 6.25em auto 0;
  padding: 0 20px;
  display: flex;
  gap: 2.5em;
}
.mainBoardArea {
  width: calc(50% - 1.25em);
}
.mainBoard_title {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.25em;
}
.mainBoard_title > img {
  margin: 0 0 0 10px;
}
.mainBoardListBox {
  margin: 1.875em 0 0;
  border-top: 1px solid #1e1e1e;
}
.mainBoardListItem {
  text-align: left;
  display: block;
  width: 100%;
  padding: 1.25em 0;
  border-bottom: 1px solid #f3f3f3;
}
.mainBoardListItem_title {
  font-size: 0.875em;
  font-weight: 500;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.mainBoardListItem_tag {
  margin: 10px 0 0;
  font-size: 0.813em;
  font-weight: 500;
  color: #9e9e9e;
}
.csType + .mainBoardListItem_title {
  margin: 6px 0 0;
}
.csType {
  font-size: 12px;
  font-weight: 700;
  color: #9e9e9e;
  border: 1px solid #f6f6f6;
  display: inline-block;
  background: #f6f6f6;
  border-radius: 4px;
  padding: 2px 8px;
}
.csType.active {
  background: #2e7ae7;
  border-color: #2e7ae7;
  color: #fff;
}
.mainNoticeSection {
  max-width: 1240px;
  margin: 2.5em auto 0;
  padding: 0 20px;
}
.mainNoticeArea {
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}
.mainNotice_contents {
  width: calc(100% - 9.938em - 10px);
  display: flex;
  align-items: center;
  padding: 15px 14px;
}
.mainNoticeLink {
  display: flex;
  align-items: center;
  background: #f3f3f3;
  border-radius: 29px;
  padding: 12px 20px;
  font-weight: 500;
  color: #4e4e4e;
  font-size: 0.875em;
  margin: 0 1.786em 0 0;
}
.mainNoticeLink img {
  margin: 0 10px 0 0;
}
.mainNoticeSlide {
  width: calc(100% - 8.688em);
}
.mainNoticeSlide .swiper {
  padding: 0.688em 0;
  height: 2.688em;
}
.mainNotice_title {
  font-size: 0.938em;
  color: #4e4e4e;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.mainNotice_btnBox {
  min-width: 9.938em;
  width: 9.938em;
  display: flex;
  justify-content: flex-end;
}
.mainNotice_prev,
.mainNotice_next {
  min-width: 2.813em;
  width: 2.813em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainNotice_link {
  width: 3.875em;
  min-width: 3.875em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.mainNotice_link::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #f3f3f3;
  width: 1px;
  height: 2.125em;
}

/*service*/
.servicePage {
  max-width: 1240px;
  margin: 0 auto;
}
.sevicePage_titleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 2em;
  margin: 0 0 2.5em;
}
.sevicePage_title {
  font-size: 1.625em;
  font-weight: 700;
}
.sevicePage_navi {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.938em;
}
.sevicePage_naviItem {
  font-weight: 500;
  color: #9e9e9e;
}
.sevicePage_naviItem.active {
  color: #1e1e1e;
  font-weight: 700;
}
.sevicePage_navi > img {
  margin: 0 4px;
}
.servicePage_contents {
  display: flex;
  gap: 2.5em;
}
.service_inputSection {
  width: calc(100% - 24.875em);
}
.service_selInfo_section {
  width: 24.875em;
  min-width: 24.875em;
}
.appSection + .appSection {
  padding: 2.5em 0 0;
  margin: 2.5em 0 0;
  border-top: 1px solid #e4e4e4;
}
.appTiileArea {
  margin: 0 0 20px;
}
.appTitle {
  font-weight: 700;
  font-size: 1.25em;
}
.appText {
  font-size: 12px;
  color: #7e7e7e;
  margin: 5px 0 0;
}
.service_minInputSection {
  max-width: 370px;
}

.infoText {
  display: flex;
  align-items: flex-start;
  font-size: 0.813em;
  color: #7e7e7e;
  margin: 6px 0 0;
}
.infoText span {
  margin: 0 6px 0 0;
}
.appSection_subArea {
  margin: 20px 0 0;
}
.appSection_subTitle {
  font-weight: 500;
  font-size: 0.875em;
}
.appSection_subContents {
  margin: 16px 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.appSection_subContents .radio_item.roundType {
  width: 6.25em;
}
.radio_item.roundType label {
  background: #f3f3f3;
  border-radius: 100px;
  color: #7e7e7e;
  border-color: #f3f3f3;
  padding: 1em;
}
.radio_item.roundType input:checked + label {
  background: #2e7ae7;
  border-color: #2e7ae7;
  color: #fff;
}
.radio_item.roundType input:checked + label .radio_item_text {
  color: #fff;
  font-weight: 700;
}
.appSection_subContents .radio_item.roundType + .infoText {
  width: 100%;
  margin: 0;
}
.service_selInfo_area {
  border: 1px solid #e4e4e4;
  border-radius: 16px 16px 0px 0px;
  padding: 0 0 10px;
}
.service_selInfo_area + .bottomImg {
  transform: translateY(-2px);
  width: 100%;
  margin: 0 0 10px;
}
.order_info_img {
  background: url(/assets/images/img/order_info_down.svg) repeat-x top -2px center;
  background-size: 100% auto;
  position: relative;
  padding: 0 0 7.88%;
  z-index: 2;
  transform: translateY(-10px);
}
.order_info_imgBox {
  height: 10px;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  background: #fff;
  transform: translateY(-12px);
  z-index: 1;
  position: relative;
}
.order_info_imgBox::before {
  content: "";
  position: absolute;
  z-index: 3;
  top: 2px;
  left: 0;
  width: 100%;
  height: 8px;
  background: #fff;
}
.order_info_imgBox span {
  position: relative;
  display: block;
  height: 6px;
  transform: translate(0, 10px);
  width: 4vw;
}
.order_info_imgBox span::before {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #e4e4e4;
  border-width: 2vw;
}
.order_info_imgBox span::after {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #fff;
  border-width: calc(2vw - 1px);
}

.service_selInfo_title {
  font-weight: 700;
  font-size: 1.125em;
  padding: 1.389em;
}
.service_selInfo_list {
  padding: 1.563em;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  background: #f8f8f8;
  min-height: 9.375em;
  max-height: 15.625em;
  overflow-y: scroll;
  height: 23vh;
}
.service_selInfo_totalArea {
  padding: 1.563em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.service_selInfo_totalTitle {
  font-weight: 500;
  font-size: 1.125em;
}
.service_selInfo_total {
  font-weight: 700;
  font-size: 1.25em;
}
.service_selInfo_infoBox {
  width: calc(100% - (1.563em * 2));
  margin: 0 auto;
  border-top: 1px solid #f3f3f3;
  padding: 1.25em 0;
}
.service_selInfo_info {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  font-size: 13px;
  color: #4e4e4e;
}
.service_selInfo_area .pageBtn_box {
  padding: 0 1.563em 20px;
}

.service_selInfo_fixedSection {
  width: 24.875em;
  min-width: 24.875em;
}
.headerFixedItem {
  position: fixed;
  top: 6.875em;
  z-index: 1000;
}
.hdFtFixedItem {
  position: absolute;
  bottom: 0;
}
.fixedPoint {
  position: relative;
}

.serviceInfoItemBox .serviceInfoItem img {
  transition: transform 0.2s;
  cursor: pointer;
}
.serviceInfoItemBox.active .serviceInfoItem img {
  transform: rotate(180deg);
}
.serviceInfoItemBox + .serviceInfoItemBox {
  margin: 13px 0 0;
}
.serviceInfoItem_name {
  font-size: 0.875em;
}
.serviceInfoItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.serviceInfoItem_textBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}
.serviceInfoSubItemBox {
  border-top: 1px solid #e4e4e4;
  margin: 14px 0 0;
  display: none;
}
.serviceInfoSubItemArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 14px 0 0;
}
.serviceInfoSubItem_name {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #7e7e7e;
  font-size: 0.813em;
}
.serviceInfoSubItem {
  color: #7e7e7e;
  font-size: 0.813em;
  text-align: right;
}
.serviceInfo_notPrice {
  font-size: 0.813em;
  font-weight: 500;
  color: #7e7e7e;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  background: #eee;
  padding: 3px 7px;
}

.addServicePopup {
  padding-left: 1.875em;
  padding-right: 1.875em;
}
.addServiceItem_title {
  width: 100%;
  text-align: left;
  font-size: 0.938em;
  font-weight: 700;
  padding: 1.333em 0;
  position: relative;
}
.addServiceItem_title::before {
  content: "";
  background: url(/assets/images/basic/select_t2.svg) no-repeat right center;
  width: 21px;
  height: 21px;
  top: 50%;
  right: 0;
  position: absolute;
  transform: translateY(-50%) rotate(180deg);
  transition: transform 0.2s;
}
.addServiceItemArea.active .addServiceItem_title::before {
  transform: translateY(-50%) rotate(0);
}
.addServiceItem_title span {
  color: #2e7ae7;
  margin: 0 0 0 6px;
}
.oilList {
  display: flex;
  flex-wrap: wrap;
  gap: 1.875em 1.25em;
  max-height: 260px;
  overflow-y: auto;
  padding: 1.875em 0;
  border-top: 1px solid #f3f3f3;
}
.oilItem {
  width: calc(25% - 0.938em);
  min-width: calc(25% - 0.938em);
}
.oilItem label {
  display: block;
}
.oilThum {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 0 0 100%;
  position: relative;
}
.oilThum img {
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: none;
}
.oilItem input:checked + label .oilThum img {
  display: block;
}
.oilItem input:checked + label .oilThum::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 2px solid #1e1e1e;
}
.oilName {
  font-size: 0.813em;
  margin: 5px 0 0;
}
.oilPrice {
  font-size: 0.875em;
  font-weight: 700;
  margin: 5px 0 0;
}
.addServiceOptions {
  border-top: 1px solid #f3f3f3;
  min-height: 3.438em;
  display: flex;
  align-items: center;
  padding: 11px 0;
}
.addServiceOptions_name {
  font-size: 0.875em;
  width: 8.571em;
}
.addServiceOptionsList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: calc(100% - 7.5em);
}
.addServiceOptionsItem {
  font-size: 0.813em;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  background: #eeeeee;
  padding: 6px 8px;
}
.addServiceTotal {
  border-top: 1px solid #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.063em 0;
  gap: 10px;
}
.addServiceTotal_name {
  font-size: 0.938em;
}
.addServiceTotal_price {
  font-weight: 700;
  font-size: 1.125em;
}

.addServicesList {
  display: flex;
  flex-wrap: wrap;
  padding: 1.875em 0;
  border-top: 1px solid #f3f3f3;
  gap: 10px;
  max-height: 16.25em;
  overflow-y: auto;
}
.addServicesRadioItem {
  width: calc(33.33% - 6.66px);
}
.addServicesRadioItem label {
  display: block;
  cursor: pointer;
}
.addServicesRadioItemText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875em;
  padding: 1.214em 10px;
  border: 1px solid #e4e4e4;
}
.addServicesRadioItem input:checked + label .addServicesRadioItemText {
  border: 1px solid #1e1e1e;
  font-weight: 500;
}
.addServicesRadioItemText img {
  display: none;
  margin: 0 8px 0 0;
}
.addServicesRadioItem input:checked + label .addServicesRadioItemText img {
  display: block;
}
.addServicesRadioItemPrice {
  font-weight: 700;
  font-size: 12px;
  color: #9e9e9e;
  margin: 7px 0 0;
  text-align: center;
}
.addServicesList + .inputItemBox {
  margin: 0 0 1.875em;
}
.addServiceCaptionList {
  border-top: 1px solid #f3f3f3;
  padding: 10px 0 0;
}
.repairCaption {
  color: #7e7e7e;
  font-size: 0.875em;
  font-weight: 500;
  width: 100%;
  margin: 0 0 1.429em;
}
.addServicesList.blockList {
  display: block;
  gap: 0;
}

.addServicesListTap {
  padding: 1.5em 0 0;
  border-top: 1px solid #f3f3f3;
  display: flex;
  gap: 8px;
  width: 100%;
  overflow-x: auto;
}
.addServicesListTapItem {
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
  border-radius: 100px;
  color: #7e7e7e;
  font-size: 0.875em;
  padding: 12px;
}
.addServicesListTapItem.active {
  background: #ffffff;
  border-color: #1e1e1e;
  font-weight: 500;
  color: #1e1e1e;
}
.addServicesListTap + .addServicesList {
  border: 0;
  margin: 1.5em 0;
}

.notCar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 14px;
  color: #9e9e9e;
  font-weight: 500;
  font-size: 0.875em;
  padding: 5em 0;
}
.notAddServicesList {
  margin: 0 auto;
  text-align: center;
  font-size: 0.875em;
  color: #9e9e9e;
  padding: 20px 0;
}

/*reat*/
.rentListSection {
  max-width: 1200px;
  margin: 0 auto;
}
.sevicePage_titleBox.notBorder {
  border: 0;
  margin: 0;
  padding: 1.25em 0 2em;
}
.categoryBox {
  display: flex;
  gap: 10px;
  padding: 0 1px;
  overflow-x: auto;
}
.categoryItem {
  background: #f3f3f3;
  border-radius: 100px;
  color: #7e7e7e;
  font-size: 0.875em;
  min-width: 7.143em;
  padding: 0.8em 1.143em;
}
.categoryItem.active {
  border: 1px solid #1e1e1e;
  background: #fff;
  font-weight: 500;
  color: #1e1e1e;
}
.categoryItem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #e4e4e4;
  width: 1px;
  height: 12px;
}
.alignArea {
  margin: 1.875em 0 0;
  display: flex;
  align-items: center;
  gap: 13px;
}
.alignItem {
  color: #7e7e7e;
  font-size: 0.875em;
  position: relative;
}
.alignItem + .alignItem {
  margin: 0 0 0 12px;
  padding: 0 0 0 12px;
}
.alignItem + .alignItem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #e4e4e4;
  width: 1px;
  height: 12px;
}
.alignItem.active {
  font-weight: 700;
  color: #1e1e1e;
}
.notItemArea {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  gap: 14px;
  padding: 6.25em 0;
}
.notItemArea > img {
  margin: 0 auto;
}
.notItemText {
  font-weight: 500;
  color: #9e9e9e;
  font-size: 0.875em;
}
.rentList_selInfo {
  background: #2e7ae7;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  position: fixed;
  bottom: 3.125em;
  width: calc(100% - 40px);
  max-width: 684px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 1.5em 1.5em 1.5em 2.15em;
  align-items: center;
}
.rentList_selInfoArea {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.rentList_selInfoArea > img {
  margin: 4px 0 0;
}
.rentList_selInfoDate {
  font-weight: 700;
  font-size: 1.25em;
  color: #fff;
}
.rentList_selInfoTotalTime {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875em;
  margin: 5px 0 0;
}
.btn_rentList_selInfoRe {
  border: 1px solid #ffffff;
  border-radius: 100px;
  font-weight: 700;
  color: #fff;
  font-size: 0.875em;
  padding: 1.1em;
  min-width: 7.857em;
}
.btn_rentList_selInfoRe > img {
  display: none;
}
.categoryFixed {
  background: #fff;
  z-index: 10;
  width: 100%;
  left: 0;
}
.categoryFixed.headerFixedItem {
  padding-bottom: 1.25em;
}
.categoryFixed.headerFixedItem .categoryBox {
  max-width: 1240px;
  padding: 0 20px;
  margin: 0 auto;
}
.categoryFixedBox {
  min-height: 2.748em;
}

.rentListArea {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5em 1.25em;
  padding: 2.5em 0;
}
.rentListItem {
  width: calc(25% - 0.938em);
}
.rentListItem_img {
  border: 1px solid #f3f3f3;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 0 0 100%;
}
.rentListItem_name {
  margin: 10px 0 0;
}
.rentListItem_price {
  margin: 4px 0 0;
  font-size: 1.25em;
  font-weight: 700;
}
.rentListItem_sale {
  color: #2e7ae7;
  margin: 0 4px 0 0;
}
.rentListItem_basicPrice {
  font-weight: 500;
  font-size: 0.875em;
  color: #d1d1d1;
  margin: 10px 0 0;
}
.rentListItem_tag {
  display: inline-flex;
  margin: 14px 0 0;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 13px;
  padding: 4px 7px;
  color: #4e4e4e;
}

.rentCarImg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 15.625em;
  height: 15.625em;
  margin: 0 auto 20px;
  max-width: 100%;
}
.rentCar_dateSection {
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  padding: 1.25em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.rentCar_dateArea {
  display: flex;
  align-items: center;
  width: 100%;
}
.rentCar_dateItem {
  width: 50%;
  text-align: center;
  padding: 0 10px;
}
.rentCar_dateName {
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 500;
}
.rentCar_date {
  font-weight: 700;
  color: #4e4e4e;
  margin: 3px 0 0;
}
.rentCar_dateTimeChk {
  background: rgba(72, 197, 129, 0.15);
  border-radius: 100px;
  padding: 10px 18px;
  font-weight: 700;
  color: #48c581;
  font-size: 0.875em;
  min-width: 10.286em;
  text-align: center;
}
.rentCarInfo {
  padding: 1.5em 0 0;
}
.rentCarInfo_name {
  font-size: 1.5em;
  font-weight: 700;
}
.rentCarInfo_price {
  margin: 10px 0 0;
  font-weight: 500;
  color: #4e4e4e;
}
.rentCarInfo + .rentCarInfo_subItem {
  margin: 25px 0 0;
}
.rentCarInfo_subItem + .rentCarInfo_subItem {
  margin: 20px 0 0;
}
.rentCarInfo_subItem_title {
  font-weight: 700;
  font-size: 0.875em;
}
.rentCarInfo_subItemList {
  margin: 12px 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 1.25em;
  align-items: center;
}
.rentCarInfo_subItemText {
  display: flex;
  align-items: center;
  color: #4e4e4e;
  font-size: 0.875em;
  gap: 8px;
}
.rentCarInfo_subItemList .dot {
  width: 2px;
  height: 2px;
  margin: 0;
}
.rentCarInfo_subItemList.dotItemBox {
  gap: 5px;
}

.inseranceSection {
  display: flex;
  justify-content: space-between;
}
.btn_inserance {
  width: 50%;
  border: 1px solid #e4e4e4;
  padding: 1.25em 10px;
  text-align: center;
  display: block;
}
.btn_inserance.active {
  background: #2e7ae7;
  border-color: #2e7ae7;
}
.btn_inserance + .btn_inserance {
  border-left: 0;
}
.inseranceInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #4e4e4e;
}
.btn_inserance.active .inseranceInfo {
  font-weight: 700;
  color: #fff;
}
.inseranceInfo img {
  display: none;
  margin: 0 6px 0 0;
}
.btn_inserance.active .inseranceInfo img {
  display: block;
}
.inseranceCaption {
  font-size: 12px;
  color: #7e7e7e;
  margin: 4px 0 0;
}
.btn_inserance.active .inseranceCaption {
  color: #fff;
}
.infoBoxTextItem {
  margin: 20px 0 0;
  background: #f9f9fa;
  border-radius: 10px;
  padding: 15px 18px;
}
.infoBoxText {
  color: #7e7e7e;
  font-size: 12px;
  line-height: 150%;
}

/*cs*/
.csPage {
  padding-left: 0;
  padding-right: 0;
}
.csDetail * {
  font-weight: revert;
  color: revert;
  font-family: revert;
  font-size: revert;
  line-height: revert;
}
.csPage_titleBox {
  padding: 20px;
  max-width: 1240px;
  margin: 0 auto;
}
.csPage_title {
  font-size: 1.625em;
  font-weight: 700;
}
.csFixedBox {
  min-width: 2.563em;
}
.csFixed {
  border-bottom: 1px solid #e4e4e4;
}
.csDetail {
  max-width: 1240px;
  margin: 0 auto;
  padding: 2.5em 20px 50px;
}
.headerFixedItem.csFixed {
  background: #fff;
  width: 100%;
}
.csList {
  padding: 2.813em 20px;
  max-width: 1240px;
  margin: 0 auto;
}
.csTotalcount {
  font-size: 0.938em;
  color: #7e7e7e;
  margin: 0 0 15px;
}
.csTotalcount span {
  color: #2e7ae7;
}
.csList_section {
  border-top: 1px solid #e4e4e4;
  padding-bottom: 3.125em;
}
.csListItem {
  display: flex;
  gap: 18px;
  align-items: flex-start;
  text-align: left;
  border-bottom: 1px solid #e4e4e4;
  width: 100%;
  padding: 1.75em 0;
}
.csListItemInfo {
  width: 100%;
}
.csListItemInfo_title {
  font-size: 1.125em;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.csListItemInfo_dateBox {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: 4px 0 0;
}
.csListItemInfo_date {
  font-size: 0.875em;
  color: #9e9e9e;
  font-weight: 500;
}
.csListItemInfo_date span {
  color: #2e7ae7;
  font-weight: 700;
  margin: 0 10px 0 0;
}
.openCsWeight {
  background: rgba(46, 122, 231, 0.15);
  border-radius: 8px;
  font-weight: 700;
  color: #2e7ae7;
  font-size: 0.875em;
  padding: 11px 20px;
  margin: 0 0 2.214em;
}
.csWrite_inputSection {
  padding: 20px;
}

.faqCateSection {
  display: flex;
  justify-content: space-between;
  gap: 1.25em;
  margin: 0 0 2.5em;
}
.faqCateItem {
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.25em 10px;
  width: calc(20% - 1em);
  gap: 1em;
}
.faqCateItem.active {
  border: 1px solid #1e1e1e;
}
.faqCateItem_name {
  color: #7e7e7e;
}
.faqCateItem.active .faqCateItem_name {
  font-weight: 500;
  color: #1e1e1e;
}

.accordionBox {
  border-bottom: 1px solid #f3f3f3;
}
.accordionTitle.faqItem {
  display: flex;
  width: 100%;
  position: relative;
  padding: 1.875em 1.25em;
}
.faqIcon {
  color: #9e9e9e;
  font-size: 1.125em;
  font-weight: 500;
  margin: 0 13px 0 0;
}
.faqTextBox {
  text-align: left;
  width: 100%;
}
.questionIcon + .faqTextBox {
  width: calc(100% - 1.125em - 2.938em - 13px - 10px);
}
.faqTitle {
  width: 100%;
  font-weight: 500;
  font-size: 1.125em;
}
.faqCate {
  font-weight: 500;
  font-size: 0.875em;
  color: #9e9e9e;
  margin: 4px 0 0;
}
.faqItem > img {
  position: absolute;
  right: 1.25em;
  top: 50%;
  transform: translateY(-50%) rotate(0);
  transition: transform 0.2s;
  width: 2.938em;
}
.accordionBox.active .faqItem > img {
  transform: translateY(-50%) rotate(180deg);
}
.accordionText {
  background: #f9f9fa;
  display: none;
  padding: 1.875em 1.25em;
  width: 100%;
}
.faqIcon.answerIcon {
  color: #2e7ae7;
}
.faqText {
  white-space: pre-wrap;
  width: 100%;
}

.csDetailHead {
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 40px);
  padding: 10px 0 1.875em;
  border-bottom: 1px solid #4e4e4e;
  display: flex;
  gap: 15px;
  align-items: flex-start;
}
.csDetailHeadTitle {
  font-weight: 500;
  font-size: 1.25em;
}
.csDetailHead_date {
  font-weight: 500;
  font-size: 0.875em;
  color: #9e9e9e;
  margin: 4px 0 0;
}
.csDetailPage .csDetail {
  border-bottom: 1px solid #f3f3f3;
  width: calc(100% - 40px);
  max-width: 1200px;
  padding-left: 0;
  padding-right: 0;
}
.csDetailBtnBox {
  margin: 2.5em auto 0;
  max-width: 260px;
}
.csDetailHead .csType {
  margin: 3px 0 0;
}
.csDetailHead_date span {
  color: #2e7ae7;
  font-weight: 700;
  margin: 0 10px 0 0;
}
.qnaDetailBox {
  border-bottom: 1px solid #f3f3f3;
  padding: 1.875em 0;
  max-width: 1200px;
  margin: 0 auto;
  width: calc(100% - 40px);
}
.question_detailText {
  font-size: 0.875em;
  line-height: 150%;
  white-space: pre-wrap;
}
.answer_detailText {
  font-weight: 500;
  font-size: 0.875em;
  padding: 2.143em;
  margin: 2.143em 0 0;
  background: #f9f9fa;
  border-radius: 10px;
  white-space: pre-wrap;
}

/*order*/
.orderPage {
  max-width: 1240px;
  margin: 0 auto;
}
.orderPageSection + .orderPageSection {
  border-top: 1px solid #e4e4e4;
  padding: 2.5em 0 0;
  margin: 2.5em 0 0;
}
.orderPageSection + .orderPageSection.notBorder {
  border: 0;
}
.orderPageTitle {
  font-weight: 700;
  font-size: 1.25em;
  margin: 0 0 1.5em;
}
.orderPageTitle .mypageState {
  display: inline-block;
  margin: 0 0 0 6px;
  transform: translateY(-3px);
}
.orderDateArea {
  display: flex;
  gap: 20px;
}
.orderTimeArea {
  width: 100%;
  padding: 1.5em;
  border: 1px solid #e4e4e4;
  border-radius: 16px;
}
.orderTimeTitle {
  color: #4e4e4e;
  font-weight: 500;
  font-size: 0.875em;
}
.orderData {
  font-size: 1.25em;
  font-weight: 500;
  margin: 8px 0 0;
}
.orderTime {
  font-weight: 500;
  color: #9e9e9e;
  margin: 5px 0 0;
}
.orderCarData {
  margin: 1.875em 0 0;
}
.orderCarDataArea {
  display: flex;
  align-items: center;
  gap: 1.25em;
  border-top: 1px solid #f3f3f3;
  padding: 1.5em 20px;
}
.orderCarDataItem {
  display: flex;
  align-items: center;
  min-width: 10em;
  gap: 15px;
}
.orderCarDataTitle {
  font-weight: 500;
}
.orderOptionArea + .orderOptionArea {
  margin: 15px 0 0;
}
.orderOptionArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  gap: 10px;
}
.orderOptionBox {
  display: flex;
  align-items: center;
  gap: 14px;
}
.orderOption_name {
  font-weight: 500;
  min-width: 10em;
}
.inputCaption .bColor {
  color: #2e7ae7;
  font-weight: 700;
}
.orderPriceArea {
  background: #f9f9fa;
  border-radius: 16px;
  padding: 1.5em;
}
.orderPriceItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
}
.orderPriceItem + .orderPriceItem {
  margin: 15px 0 0;
}
.orderPriceItem_name {
  font-weight: 500;
}
.orderPriceItem_text span {
  font-size: 1.125em;
}
.orderPriceSubItem {
  border-top: 1px solid #e4e4e4;
  margin: 1.25em 0 0;
}
.orderTotalPriceArea {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 14px;
  margin: 1.875em 0 0;
}
.orderTotalPrice_name {
  font-weight: 500;
  font-size: 1.125em;
}
.orderTotalPriceBox {
  text-align: right;
}
.orderTotalPrice {
  font-size: 1.375em;
  font-weight: 700;
}
.orderTotalPrice_point {
  color: #2e7ae7;
  margin: 4px 0 0;
  font-weight: 500;
  font-size: 0.875em;
}
.orderPayArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.btnPayItem {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  width: calc(20% - 8px);
  min-width: calc(20% - 8px);
  min-height: 3.875em;
}
.btnPayItem img {
  margin: 0 auto;
}
.btnPayItem.active {
  background: #1e1e1e;
  border-color: #1e1e1e;
  color: #fff;
  font-weight: 700;
}
.orderChkArea {
  margin: 1.875em 0 0;
}
.chk_item.pageIn {
  background: #fff;
  border-radius: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}
.chk_item.pageIn label {
  width: auto;
}
.chk_item.pageIn .chk_item_text {
  color: #1e1e1e;
}
.chk_item.pageIn .chk_item_text span {
  color: #9e9e9e;
}
.chk_item.pageIn .chkBoxBtn {
  font-weight: 700;
  color: #9e9e9e;
}
.payInfoTextBox {
  padding: 20px 0 0;
  margin: 2.5em 0;
  border-top: 1px solid #e4e4e4;
}
.payInfoTextBox.notLine {
  border: 0;
  margin: 0;
}
.notPayBox {
  margin: 2.5em 0 0;
}

.orderComPage_contents {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3.125em 0;
}
.orderComHead img {
  margin: 0 auto;
  width: 10em;
}
.orderComHead_title {
  text-align: center;
  font-weight: 300;
  font-size: 1.875em;
  margin: 0.833em 0 0;
}
.orderComHead_number {
  text-align: center;
  margin: 1em 0 0;
  color: #4e4e4e;
}
.orderComHead_number span {
  margin: 0 0 0 6px;
  font-weight: 700;
  color: #2e7ae7;
}
.orderComSection {
  margin: 2.5em 0 0;
}
.orderComSection_title {
  font-weight: 700;
  font-size: 1.125em;
  margin: 0 0 1.556em;
}
.orderComTable {
  border-top: 1px solid #1e1e1e;
}
.orderComTableItem {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
}
.orderComTableItem_name {
  font-size: 0.813em;
  min-width: 13.846em;
  width: 13.846em;
  padding: 18px 20px;
  font-weight: 700;
  border-right: 1px solid #e4e4e4;
}
.orderComTableItem_text {
  padding: 18px 20px;
  font-size: 0.875em;
  width: calc(100% - 12.857em);
}
.orderComTableItem_text span {
  color: #9e9e9e;
  text-decoration-line: line-through;
  margin: 0 3px 0 0;
}
.orderComInfoTextBox {
  background: #f9f9fa;
  border-radius: 8px;
  text-align: center;
  padding: 15px 20px;
  margin: 1.5em 0 0;
}
.orderComInfoText {
  font-size: 0.875em;
}
.orderComInfoText span.bColor {
  display: block;
  text-align: center;
  color: #2e7ae7;
  line-height: 180%;
  font-weight: 700;
}
.orderComSection + .pageBtn_box {
  margin-top: 2.5em;
}
.orderComBanner {
  margin: 6.25em 0 0;
}
.orderComTableItem_textLink {
  font-weight: 700;
  text-decoration-line: underline;
}

/*mypage*/
.mypage {
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  gap: 2.5em;
}
.mypageMenu {
  width: 23.75em;
  min-width: 23.75em;
}
.mypageMenu_myInfo {
  background: #38383a;
  border-radius: 22px;
  padding: 1.875em;
}
.mypageMenu_myInfo_name {
  font-size: 1.5em;
  color: #fff;
  display: flex;
  align-items: center;
}
.mypageMenu_myInfo_name img {
  width: 22px;
  margin: 0 0 0 6px;
}
.mypageMenu_myInfo_name span {
  font-weight: 700;
}
.mypageMenu_myInfo_number {
  font-size: 0.938em;
  color: #9e9e9e;
  margin: 5px 0 0;
}
.mypageMenu_myInfo_btnBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 2.5em 0 0;
}
.mypageMenu_editLink {
  border: 1px solid #7e7e7e;
  border-radius: 8px;
  font-weight: 500;
  color: #f3f3f3;
  font-size: 0.875em;
  padding: 11px 5px;
  text-align: center;
  max-width: 8.571em;
  width: 8.571em;
}
.mypageMenu_editLinkBox {
  position: relative;
}
.mypageMenu_carInfo {
  margin: 1.125em 0 0;
  border: 1px solid #e4e4e4;
  border-radius: 22px;
  padding: 1.5em 1.875em;
}
.mypageMenu_carInfo_titleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #f3f3f3;
  padding: 0 0 1.125em;
  margin: 0 0 1.125em;
}
.mypageMenu_carInfo_title {
  font-weight: 500;
  font-size: 1.125em;
}
.mypageMenu_carInfoBtn {
  font-weight: 700;
  color: #2e7ae7;
  font-size: 0.875em;
  background: rgba(46, 122, 231, 0.15);
  border-radius: 8px;
  padding: 8px 15px;
}
.mypageMenu_carInfo_notTitle {
  color: #9e9e9e;
  font-weight: 700;
  font-size: 1.125em;
}
.mypageMenu_carInfo_carName,
.mypageMenu_carInfo_notText {
  color: #9e9e9e;
  font-weight: 500;
  margin: 5px 0 0;
  font-size: 0.813em;
}
.mypageMenu_carInfo_carNumber {
  font-weight: 700;
  font-size: 1.125em;
}
.mypageMenu_carInfo_carName {
  display: flex;
  align-items: center;
}
.mypageMenu_carInfo_carName .dot {
  background: #9e9e9e;
}
.mypageMenu_item {
  border: 1px solid #e4e4e4;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.75em 1.875em;
  gap: 10px;
  margin: 1.125em 0 0;
  cursor: pointer;
}
.mypageMenu_item_nameBox {
  display: flex;
  align-items: center;
}
.mypageMenu_item_nameBox img {
  margin: 0 10px 0 0;
}
.mypageMenu_item_name {
  font-size: 1.125em;
  font-weight: 500;
}
.mypageMenu_item_subBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.mypageMenu_item_subText {
  font-size: 1.125em;
  color: #4e4e4e;
}
.mypageMenu_item_subText.boldText {
  font-weight: 700;
}
.mypageMenuTermsBox {
  background: #f9f9f9;
  border-radius: 22px;
  margin: 1.125em 0 0;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.mypageMenuTerms + .mypageMenuTerms::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #e4e4e4;
  width: 1px;
  height: 24px;
}
.mypageMenuTerms {
  width: 33.33%;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  padding: 20px 15px;
}
.btn_withdrawal {
  color: #9e9e9e;
  text-decoration-line: underline;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
}
.withdrawalBtnBox {
  text-align: center;
  margin: 18px 0 0;
}
.mypageContents {
  width: calc(100% - 23.75em - 2.5em);
}
.withdrawalSection {
  padding: 10px 20px 0;
}
.withdrawal_title {
  font-weight: 700;
  font-size: 0.875em;
  margin: 0 0 2.143em;
}
.withdrawalSection .infoText {
  color: #1e1e1e;
}
.withdrawal_subText {
  margin: 2.143em 0 0;
  color: #7e7e7e;
  line-height: 160%;
  font-size: 0.875em;
}
.withdrawal_subText + .chk_item {
  margin: 1.875em 0 0;
}
.mypageMainTitle {
  font-weight: 700;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.mypageMenu_item.mItem {
  display: none;
}
.mypageMenu_item.notLink {
  cursor: auto;
}
.withdrawalChkBox {
  position: fixed;
  bottom: 79px;
  left: 0;
  width: 100%;
  padding: 20px 20px 0;
  background: #fff;
  z-index: 10;
}

.mypageMainTableArea + .mypageMainTableArea {
  margin: 3.125em 0 0;
}
.mypageMainTableArea_titleBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 0 0 1.25em;
}
.mypageMainTableArea_title {
  font-weight: 700;
  font-size: 1.125em;
}
.mypageMainTableArea_titleLink {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  color: #4e4e4e;
  font-weight: 500;
  font-size: 0.875em;
}
.mypageMainTableArea .notItemArea {
  background: #f3f3f3;
  border-radius: 16px;
  padding: 4.9em 0;
}

.myPageTapBox + .mypageTable {
  margin: 3.125em 0 0;
}
.mypageTable {
  border-top: 1px solid #1e1e1e;
}
.mypageTableThBox {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
}
.mypageTableTh {
  text-align: center;
  font-weight: 700;
  font-size: 0.813em;
  padding: 1.308em 5px;
}
.mypageTableTdBox {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f3f3f3;
}
.mypageTableTd {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 5px;
  min-height: 5.429em;
  text-align: center;
  font-size: 0.875em;
}
.mypageTableTd.notLink {
  cursor: auto;
}
.mypageState {
  border-radius: 4px;
  width: 59px;
  font-weight: 700;
  font-size: 12px;
  padding: 2px 6px;
  color: #9e9e9e;
  background: #f6f6f6;
  text-align: center;
}
.mypageState.wbColor {
  background: #e0ebfb;
  color: #2e7ae7;
}
.mypageState.bColor {
  background: #2e7ae7;
  color: #fff;
}
.mypageState.rColor {
  background: #fde7e2;
  color: #f05e3d;
}
.mypageState.gColor {
  background: rgba(72, 197, 129, 0.15);
  color: #48c581;
}
.rentTableList {
  display: flex;
  align-items: center;
  gap: 12px;
}
.rentTableList_img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid #f3f3f3;
  min-width: 4.286em;
  width: 4.286em;
  height: 4.286em;
}
.rentTableList_infoBox {
  text-align: left;
}
.rentTableList_info {
  font-size: 12px;
  color: #9e9e9e;
  margin: 3px 0 0;
}
.mypageState + span {
  margin: 0 0 0 10px;
}
.tableSubTitle {
  color: #7e7e7e;
  min-width: 90px;
  font-weight: 500;
}

.myEditCarInfo {
  padding: 3.125em 0;
}
.myEditCarInfoTextBox {
  margin: 3.125em 0 0;
  padding: 1.875em 0 0;
  border-top: 1px solid #f3f3f3;
}
.editCarInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: 10px 0 0;
}
.editCarInfoName {
  color: #7e7e7e;
  font-size: 0.875em;
}
.editCarInfoText {
  text-align: right;
  font-weight: 500;
  font-size: 0.875em;
}

.mypageTableArea_titleBox {
  padding: 20px 0 2.75em;
}
.mypageTableArea_title {
  font-weight: 700;
  font-size: 1.625em;
}
.myPointBox {
  background: #f9f9fa;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.188em 1.75em;
}
.myPointTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4e4e4e;
  font-size: 1.125em;
}
.myPoint {
  text-align: right;
  font-weight: 700;
  color: #2e7ae7;
  font-size: 1.375em;
}
.myPointListArea {
  margin: 1.875em 0 0;
}
.myPageTapBox {
  border-bottom: 1px solid #e4e4e4;
}
.myPageTapBox .tapBox {
  padding: 0;
  transform: translateY(1px);
}
.myPointListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 1.5em 0;
  border-bottom: 1px solid #f3f3f3;
}
.myPointList_type {
  font-weight: 500;
  font-size: 1.125em;
}
.myPointList_state {
  color: #7e7e7e;
  margin: 8px 0 0;
  font-size: 0.875em;
  font-weight: 700;
}
.myPointList_state.bColor {
  color: #2e7ae7;
}
.myPointList_state span {
  margin: 0 0 0 10px;
  color: #9e9e9e;
  font-weight: 400;
}
.myPointListItem_pointBox {
  text-align: right;
}
.myPointList_nowPoint {
  color: #9e9e9e;
  font-size: 0.875em;
  margin: 0 0 8px;
}
.myPointList_point {
  font-weight: 500;
  color: #7e7e7e;
  font-size: 1.125em;
}
.myPointList_point.bColor {
  color: #2e7ae7;
}
.addListBtn {
  margin: 3.125em auto 0;
}
.addListBtn .pageBtn {
  height: 42px;
  max-width: 220px;
  margin: 0 auto;
}

.myDetailPage_contents {
  max-width: 1200px;
  margin: 0 auto;
}
.mypageDetailTitleBox {
  margin: 1.25em 0 0;
}
.mypageDetailTitle_title {
  font-weight: 700;
  font-size: 1.625em;
}
.mypageDetailNumber {
  font-size: 1.125em;
  color: #4e4e4e;
  margin: 1.667em 0 0;
  display: flex;
  align-items: center;
}
.mypageDetailNumber .mypageState {
  margin: 0 10px 0 0;
}
.mypageDetailNumber span {
  color: #2e7ae7;
  font-weight: 700;
  margin: 0 0 0 3px;
}
.orderComSection_title .mypageState {
  display: inline-block;
  margin: 0 12px 0 0;
  transform: translateY(-1px);
}
.mypageDetailOrderLinkArea {
  background: #f9f9fa;
  border-radius: 16px;
  padding: 1.875em;
  text-align: center;
}
.mypageDetailOrderLink_text {
  font-size: 13px;
  color: #7e7e7e;
}
.mypageDetailOrderLink {
  border: 1px solid #7e7e7e;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.875em;
  min-width: 11.429em;
  padding: 9px 10px;
  margin: 10px auto 0;
}
.orderComSection_col2 {
  display: flex;
  justify-content: space-between;
  gap: 2.5em;
}
.orderComSection_col2 .orderComSection {
  width: 100%;
}
.orderComSection_col2 + .pageBtn_box,
.orderComSection + .pageBtn_box {
  margin: 2.5em auto 0;
}
.mypageDetailOrderLink img {
  width: 22px;
  margin: 0 0 0 8px;
}
.orderComSection + .service_selInfo_info {
  margin: 20px 0 0;
}
.orderComSection .sub_lineItem {
  display: block;
  width: 100%;
  height: 1px;
  background: #e4e4e4;
  margin: 2.5em 0 20px;
}

.rentCarInfo_contents {
  display: flex;
  align-items: flex-start;
  gap: 1.875em;
  max-width: calc(100% - 3.75em);
  margin: 0 auto 5px;
  padding: 1.875em 0;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}
.rentCarInfo_img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 9.375em;
  min-width: 9.375em;
  height: 9.375em;
  border: 1px solid #e4e4e4;
}
.rentCarInfoPopup .rentCarInfo {
  padding: 0;
}
.rentCarInfoPopup .rentCarInfo_name {
  font-size: 1.25em;
}
.rentCarInfoPopup .rentCarInfo_subItem {
  margin: 20px 0 0;
}
.rentCar_notOptions {
  font-size: 0.875em;
  color: #4e4e4e;
}
.rentCancel_inputSection {
  min-height: 16.063em;
}

/*반응형*/
@media (min-width: 721px) {
  /*popup*/
  .popup.btnInPopup {
    position: absolute;
    width: 15em;
    max-width: 15em;
    height: auto;
    top: calc(100% + 4px);
    right: 0;
    left: inherit;
  }
  .popup.btnInPopup .popup_cover {
    display: none;
  }
  .popup.btnInPopup .popupSetion {
    max-width: 240px;
    position: static;
    transform: translate(0, 0);
    width: 100%;
    max-height: 100%;
    height: auto;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    padding: 8px 15px;
  }
  .popup.btnInPopup .popup_header_section {
    display: none;
  }
  .popup.btnInPopup .popupLinkArea {
    margin: 0;
  }
  .popup.btnInPopup .popupLinkItem {
    font-size: 0.875em;
    padding: 1.143em 0;
  }
}
@media (min-width: 1025px) {
  /*main*/
  .mainMenuItem.minType {
    height: 10.313em;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
    flex-direction: row;
  }
  .mainMenuItem.minType .mainMenuItem_captionBox {
    width: auto;
  }
}
@media (max-width: 1800px) {
}
@media (max-width: 1680px) {
}
@media (max-width: 1600px) {
  html,
  body {
    font-size: 15px;
  }
}
@media (max-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media (max-width: 1366px) {
  html,
  body {
    font-size: 13px;
  }
}
@media (max-width: 1280px) {
  html,
  body {
    font-size: 12px;
  }
}
@media (max-width: 1240px) {
  .mainService_slideArea {
    width: 75%;
  }
}
@media (max-width: 1152px) {
  html,
  body {
    font-size: 11px;
  }
}
@media (max-width: 1024px) {
  html,
  body {
    font-size: 13px;
  }

  /*main*/
  .mainBanner_seciton {
    display: block;
  }
  .mainBannerItem {
    padding: 0 0 69.71%;
    height: auto;
  }
  .mainBannerArea {
    width: 100%;
  }
  .mainBanner_menuArea {
    margin: 21px 0 0;
    width: 100%;
  }
  .mainMenuItem {
    width: calc(33.33% - 13.33px);
    height: 310px;
  }
  .mainService_controlArea {
    min-width: 300px;
    width: 300px;
  }
  .mainService_slideArea {
    width: calc(100% - 300px);
  }
}
@media (max-width: 960px) {
  .pctItem {
    display: none;
  }
  .tItem {
    display: inline-block;
  }

  /*basic*/
  .headerSection {
    position: relative;
    padding: 40px 20px 0;
    min-height: 90px;
    justify-content: center;
  }
  .btn_logo {
    position: absolute;
    left: 20px;
    top: calc(100% - 15px);
    transform: translateY(-100%);
  }
  .headerSubMenuArea {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 20px;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #f3f3f3;
  }
  .headerInPage {
    padding-top: 90px;
  }
  .headerFixedItem {
    top: 90px;
  }

  /*service*/
  .service_inputSection {
    width: calc(100% - 300px);
  }
  .service_selInfo_section {
    min-width: 300px;
    width: 300px;
  }
  .service_selInfo_fixedSection {
    min-width: 300px;
    width: 300px;
  }

  /*rent*/
  .rentListItem {
    width: calc(33.33% - 13.33px);
  }
  .rentCar_dateSection {
    display: block;
    align-items: flex-start;
    justify-content: flex-start;
    border: 0;
    padding: 0;
  }
  .rentCar_dateArea {
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0;
  }
  .rentCar_dateTimeChk {
    margin: 22px 0 0;
    min-width: 0;
  }
  .inseranceSection {
    display: block;
  }
  .btn_inserance {
    width: 100%;
  }
  .btn_inserance + .btn_inserance {
    border-left: 1px solid #e4e4e4;
  }

  /*cs*/
  .faqCateSection {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
  }
  .faqCateItem {
    width: calc(33.33% - 6.66px);
    padding: 13px 5px;
    gap: 8px;
  }
  .faqCateItem img {
    width: 22px;
  }

  .mTypeItem .mypageTableThBox {
    display: none;
  }
  .mTypeItem .mypageTableTdBox {
    flex-direction: column;
    gap: 15px;
    text-align: left;
    padding: 18px 0;
    background: url(/assets/images/basic/move.svg) right top 18px no-repeat;
  }
  .mTypeItem .mypageTableTd {
    font-size: 13px;
    width: 100% !important;
    min-width: 100% !important;
    justify-content: flex-start;
    height: auto;
    min-height: auto;
    padding: 0;
    text-align: left;
  }
  .mTypeItem .mypageTableTd br {
    display: none;
  }
}
@media (max-width: 720px) {
  .pcItem {
    display: none;
  }
  .mItem {
    display: block;
  }

  /*basic*/
  .headerInPage {
    padding-top: 58px;
  }
  .mainPage.headerInPage {
    padding-top: 0;
  }
  .mHeaderNot.headerInPage {
    padding-top: 0;
  }
  .footerMenuInPage {
    padding-bottom: 100px;
  }
  .mBtnPage {
    padding-bottom: 125px;
  }
  .btnInPage {
    padding-bottom: calc(96px + 50px);
  }
  .pageBtn_box.pcMinBtn .pageBtn {
    max-width: 100%;
  }
  .headerFixedItem {
    top: 58px;
  }
  .alignArea {
    margin: 15px 0 0;
  }
  .inputCaption {
    font-size: 12px;
  }
  .errMsg {
    font-size: 12px;
  }
  .popupSetion {
    top: initial;
    max-width: 100% !important;
    padding: 0;
    border-radius: 0;
    bottom: 0;
    left: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    opacity: 1;
    width: 100%;
    transform: translateY(100%);
    transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91);
  }
  .btnPopupSetion .popupSetion {
    padding-bottom: 90px;
  }
  .bottomErrMsg {
    position: fixed;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 100px;
  }
  .popupPageItem,
  .popup_header_section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .popup.btnInPopup .popupPageItem {
    padding: calc(1.875em + 30px + 1.563em) 20px 0;
  }

  /*footer*/
  .footerMenu.mItem {
    display: flex;
  }
  .footer {
    border-top: 1px solid #f3f3f3;
    background: #fff;
  }
  .footer_title {
    display: none;
  }
  .footer_menuBox {
    width: 100%;
    margin: 0 0 20px;
    justify-content: center;
  }
  .footer_menuItem {
    color: #4e4e4e;
  }
  .footer_infoText {
    color: #4e4e4e;
    justify-content: center;
    display: block;
    text-align: center;
  }
  .footer_infoText span {
    color: #4e4e4e;
    min-width: 0;
    display: inline-block;
  }
  .footer_text {
    margin: 18px 0 0;
    color: #4e4e4e;
    text-align: center;
  }

  /*popup*/
  .rightPopup {
    transform: translateX(100%);
    transition: transform 0.2s cubic-bezier(0.89, 0.11, 0.09, 0.89);
    opacity: 1;
  }
  .rightPopup.active {
    transform: translateX(0);
  }
  .rightPopup > .popup_cover {
    display: none;
  }
  .rightPopup_section {
    width: 100%;
    height: 100%;
    border-radius: 0;
    max-width: 100%;
    max-height: 100%;
  }
  .rightPopup_header_section {
    justify-content: center;
  }
  .rightPopup_title {
    text-align: center;
  }
  .minVar .rightPopup_section {
    max-width: 100%;
  }
  .rightPopup_contents {
    max-height: calc(100vh - 4.25em);
  }
  .rightPopupPageItemBox {
    max-height: calc(100vh - 4.25em);
  }
  .rightPopup_contents.addBtnContents .terms_text {
    padding-bottom: 116px;
  }

  .popup.active .popupSetion {
    transform: translateY(0);
  }
  .radio_item {
    width: calc(33.33% - 6.66px);
  }
  .popupBtnBtn {
    max-width: calc(100% - 40px);
  }

  .inputItemBox + .col2Input {
    margin: 12px 0 0;
  }
  .col2Input + .col2Input {
    margin: 12px 0 0;
  }
  .addServiceItem + .col2Input {
    margin: 12px 0 0;
  }
  .inputItemBox + .inputItemBox {
    margin: 12px 0 0;
  }
  .service_minInputSection + .inputItemBox,
  .inputItemBox + .service_minInputSection {
    margin: 12px 0 0;
  }
  .col2Input {
    gap: 12px;
  }
  .col2Input .inputItemBox + .inputItemBox {
    margin: 0;
  }

  .pageBtn_box.mChType {
    position: fixed;
    padding: 20px;
  }
  .popupBtn_box.mChType {
    position: fixed;
    position: fixed;
    padding: 20px 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
  }
  .addServicePopup {
    height: 100%;
    padding: 0 20px 80px;
  }
  .oilList {
    gap: 20px 10px;
  }
  .oilItem {
    width: calc(33.33% - 10px);
    min-width: calc(33.33% - 10px);
    max-height: 300px;
  }
  .addServicePopup {
    overflow-y: auto;
  }
  .carAddPopup_section {
    min-height: 0;
  }
  .terms_text {
    max-height: 100vh;
  }

  /*member*/
  .memberSection {
    padding: 0;
    border: 0;
    border-radius: 0;
    max-width: 100%;
    margin: 0;
  }
  .memberTitleArea + .memberArea .memberInput_section:nth-child(1) {
    margin-top: 20px;
  }
  .memberTitleArea + .memberArea .memberInput_section.joinInput_section {
    margin-top: 20px;
    padding: 0;
    border: 0;
  }

  /*main*/
  .mainBanner_seciton {
    padding: 0;
  }
  .mainBanner_menuArea {
    gap: 10px;
    padding: 0 20px;
  }
  .mainBannerItem {
    border-radius: 0;
  }
  .mainMenuItem {
    height: 180px;
    position: relative;
    width: calc(50% - 5px);
  }
  .mainMenuItem.minType {
    width: 100%;
    height: auto;
  }
  .mainMenuItem_captionBox {
    position: absolute;
    text-indent: -9999px;
    width: 21px;
    height: 21px;
    right: 11px;
    top: 20px;
  }
  .mainMenuItem_caption {
    background-position: center;
    height: 100%;
    padding: 0;
  }
  .mainService_controlBtnBox {
    display: none;
  }
  .mainServiceSection {
    margin: 60px auto 0;
  }
  .mainService_title {
    font-size: 16px;
  }
  .mainService_slideSection {
    flex-direction: column;
    gap: 30px;
    padding: 0;
    margin: 22px auto 0;
  }
  .mainService_controlArea {
    width: 100%;
    min-width: 0;
    padding: 0 20px;
  }
  .mainService_slideArea {
    width: 100%;
  }
  .mainService_slideArea .swiper {
    padding: 0 30px;
  }
  .swiper-slide-active .mainService_slideItem {
    transform: translateY(-20px);
  }
  .mainService_slideArea .swiper {
    padding-top: 20px;
  }
  .middleBanner_pagination {
    display: none;
  }
  .mainBoard_section {
    flex-direction: column;
    gap: 30px;
  }
  .mainBoardArea {
    width: 100%;
  }
  .mainNotice_contents {
    padding: 10px;
  }
  .mainMenuItem_text {
    font-size: 12px;
  }

  /*service*/
  .servicePage {
    padding-left: 0;
    padding-right: 0;
  }
  .servicePage_contents {
    display: block;
    gap: 0;
  }
  .service_inputSection {
    width: 100%;
  }
  .service_minInputSection {
    max-width: 100%;
  }
  .appSection {
    padding: 30px 20px 0;
  }
  .appSection + .appSection {
    border-top: 10px solid #f8f8f8;
    margin-top: 30px;
    padding: 30px 20px 0;
  }
  .service_selInfo_section {
    border-top: 10px solid #f8f8f8;
    margin-top: 30px;
    padding: 30px 20px 70px;
    min-width: 0;
    width: 100%;
  }
  .service_selInfo_fixedSection {
    min-width: 0;
    width: 100%;
    position: static !important;
  }
  .addServiceItem {
    position: relative;
  }
  .chk_item.addServiceItem label {
    padding: 15px;
  }
  .addServiceItem_name.mChType {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .addServiceCaption.mChType {
    position: absolute;
    right: -15px;
    transform: translateY(calc(-100% - 15px));
    width: auto;
    white-space: nowrap;
  }
  .addServiceCaption.mChType::after {
    border-top: 4px solid #e4f6ec;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 0px solid transparent;
    content: "";
    position: absolute;
    bottom: 0;
    left: 76%;
    transform: translateY(100%);
  }
  .service_selInfo_list {
    height: auto;
    min-height: 0;
    max-height: initial;
  }
  .order_info_imgBox.mItem {
    display: flex;
  }
  .addServicesRadioItem {
    width: calc(50% - 5px);
  }

  /*rent*/
  .rentList_selInfo {
    border-radius: 0;
    width: 100%;
    max-width: 100%;
    bottom: 0;
    padding: 20px;
  }
  .rentList_selInfoArea img {
    display: none;
  }
  .rentList_selInfoDate {
    font-size: 13px;
  }
  .btn_rentList_selInfoRe {
    border-radius: 8px;
    text-indent: -9999px;
    padding: 0;
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn_rentList_selInfoRe > img {
    display: block;
  }
  .rentListArea {
    gap: 30px 10px;
  }
  .rentListItem {
    width: calc(50% - 5px);
  }

  /*tap*/
  .tapItem {
    font-size: 14px;
    padding: 5px 5px 17px;
  }

  /*cs*/
  .csPage_title {
    font-size: 20px;
  }
  .headerFixedItem.csFixed {
    top: 0;
  }
  .headerFixedItem.csFixed .tapItem {
    padding-top: 17px;
  }
  .csListItem {
    padding: 18px 0;
  }
  .btn_pageNation {
    width: 29px;
    min-width: 29px;
  }
  .btn_pageNation img {
    width: 100%;
  }
  .btn_pageNationNumber {
    width: 29px;
    height: 29px;
    min-width: 29px;
  }
  .csListItem {
    flex-direction: column;
    gap: 10px;
  }
  .openCsWeight {
    width: 100%;
  }
  .faqIcon {
    margin-right: 10px;
  }
  .faqTitle {
    font-size: 14px;
  }
  .accordionTitle.faqItem {
    padding: 20px 10px;
  }
  .accordionText {
    padding: 20px 10px;
  }
  .faqItem > img {
    right: 10px;
  }
  .csDetailHead {
    flex-direction: column;
    gap: 8px;
    padding: 18px 0;
  }
  .csDetailHead_date span {
    margin: 0 6px 0 0;
  }
  .csDetailHead .csType {
    margin: 0;
  }

  /*order*/
  .orderPage_contents {
    padding: 20px 0 0;
  }
  .orderDateArea {
    gap: 10px;
    flex-direction: column;
  }
  .orderCarDataArea {
    padding: 20px 0;
    gap: 10px;
  }
  .orderCarDataItem {
    min-width: 110px;
    gap: 13px;
  }
  .orderOptionBox {
    justify-content: space-between;
    width: 100%;
  }
  .orderOption_name {
    min-width: 95px;
  }
  .orderPayArea {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .btnPayItem {
    width: calc(50% - 5px);
    min-width: calc(50% - 5px);
  }

  .orderComPage_contents {
    padding: 0;
  }
  .orderComHead img {
    width: 80px;
  }
  .orderComHead_title {
    font-size: 20px;
  }
  .orderComHead_number {
    margin: 3px 0 0;
  }
  .orderComTableItem_name {
    padding: 13px 10px;
    width: 140px;
    min-width: 140px;
  }
  .orderComTableItem_text {
    padding: 13px 10px;
  }
  .orderComBanner {
    margin: 40px 0 0;
  }

  /*mypage*/
  .mypageMenu {
    max-width: 100%;
    width: 100%;
    min-width: 0;
  }
  .mypageContents {
    width: 100%;
  }
  .mypageMenuTerms {
    padding: 15px 8px;
  }
  .mypageMenu_item.mItem {
    display: flex;
  }
  .myEditCarInfo {
    min-height: calc(100vh - 58px - 96px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 70px 0 0;
  }
  .addListBtn .pageBtn {
    max-width: 100%;
  }
  .myPointBox {
    padding: 25px 20px;
  }

  .mypageTableArea.mTMergin {
    margin: 15px 0 0;
  }
  .mypageDetailTitleBox {
    margin: 0;
  }
  .rentCarInfo_contents {
    border: 0;
    padding: 0 0 100px;
    flex-direction: column;
    gap: 10px;
  }
  .rentCarInfo_img {
    min-width: 200px;
    width: 200px;
    height: 200px;
    border: 0;
    margin: 0 auto;
  }
}
@media (max-width: 650px) {
  /*datepicker*/
  .react-datepicker {
    display: block;
    max-height: 360px;
    overflow-y: scroll;
  }
  .react-datepicker__month-container {
    float: none;
  }

  /*input*/
  .col2Input .react-datepicker-popper {
    width: 100%;
  }
  .col2Input {
    flex-wrap: wrap;
    gap: 0;
  }
  .col2Input .inputItemBox + .inputItemBox {
    margin: 12px 0 0;
  }

  /*main*/
  .mainNoticeArea {
    flex-direction: column;
    position: relative;
  }
  .mainNotice_contents {
    padding: 10px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  .mainNoticeLink {
    text-align: left;
    margin: 0 0 10px;
    padding: 8px 15px;
  }
  .mainNoticeSlide {
    width: 100%;
  }
  .mainNotice_btnBox {
    position: absolute;
    right: 0;
    top: 10px;
    height: 31px;
  }
}
@media (max-width: 550px) {
}
@media (max-width: 490px) {
}
@media (max-width: 400px) {
}
@media (max-width: 340px) {
}
/*//*/
